<template>
    <section class="row payment-form">
        <h5>
            クレジットカード情報の変更
        </h5>

        <div class="has-text-centered">
            <p>
                {{ pagedata['page_keyword'] }}（{{ pagedata['business_name'] }}）
            </p>
            <a :href="'https://jp.ilb.io/' + pagedata['page_id']" target="_blank">
                https://jp.ilb.io/{{ pagedata['page_id'] }}
            </a>
            <p class="help">
                クレジットカード情報は、ページ毎での登録となります<br>
                ページ利用料と広告費のカード情報を別にすることはできません
            </p>
        </div>

        <hr>

        <h6>
          現在のカード番号<br>
          ●●●● {{ last4 }}（{{ brand }}）
        </h6>

        <p class="help py-3">
            Visa、Mastercard、JCB、American Express、Diners Clubがご利用いただけます。
        </p>

        <b-field label="カード番号（ハイフンなし）">
          <div class="col s12 card-element">
            <div class="error">{{stripeValidationErrorCardnumber}}</div>
            <div id="card-number-element" class="input-value"></div>
          </div>
        </b-field>

        <b-field label="有効期限">
          <div class="col s6 card-element">
            <div class="error">{{stripeValidationErrorExpired}}</div>
            <div id="card-expiry-element"></div>
          </div>
        </b-field>
        <div class="comment">カードに記載されている通り入力してください</div>

        <b-field label="セキュリティーコード（CVC）">
          <div class="col s6 card-element">
              <div id="card-cvc-element"></div>
          </div>
        </b-field>
        <div class="comment">セキュリティコードとは、クレジットカードの裏面に記載されている3桁の数字です。</div>
        <div class="comment">AMEXの場合は、カード表面右上にある4桁の数字です。</div>

        <div class="col s12 place-order-button-block">
            <button v-if='success === false' class="btn col s12 #e91e63 pink" @click="placeOrderButtonPressed('page')">カード情報を変更する</button>
            <b-button v-if="success === 'loading'" loading>Loading</b-button>
            <b-button v-if='success === true' class="btn col s12 #e91e63 pink" @click="modalClose">閉じる</b-button>
            <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>

        </div>
    </section>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js'
import Config from '../../config.js'

export default {
    props: ['pagedata'],
    data() {
        return {
            brand: null,
            last4: null,
            customer_id: null,
            stripe: null,
            cardNumberElement: null,
            cardExpiryElement: null,
            cardCVCElement: null,
            stripeValidationError: "",
            amount:Config.AMOUNT,
            stripeValidationErrorCardnumber: "",
            stripeValidationErrorExpired: "",
            success:false,
            isLoading: false,
            isFullPage: true,

        }
    },
    async mounted() {
      this.stripe = await loadStripe(Config.PUBLIC_KEY)
      this.createAndMountFormElements()
      this.getCustomerId()
    },

    methods: {
        async getCustomerId () {
          const charges_doc = await Config.CHARGES_COLLECTION.doc(this.pagedata.page_id).get()
          this.customer_id = charges_doc.data().customer_id
          this.last4 = charges_doc.data().last4
          this.brand = charges_doc.data().brand
        },
        // stripe UIを使って決済画面elementの作成
        createAndMountFormElements() {
            const elementStyles = {
                base: {
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                    color: '#CFD7E0',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    },
                },
                invalid: {
                    color: '#E25950',

                    '::placeholder': {
                        color: 'red',
                    },
                },
            }

            const elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid',
            }

            const elements = this.stripe.elements()
            this.cardNumberElement = elements.create("cardNumber" , {
                  style:  elementStyles,
                  class:  elementClasses,
            })
            this.cardNumberElement.mount("#card-number-element")
            this.cardExpiryElement = elements.create("cardExpiry" , {
                  style: elementStyles,
                  class:  elementClasses,
            })
            this.cardExpiryElement.mount("#card-expiry-element")
            this.cardCvcElement = elements.create("cardCvc" , {
                  style: elementStyles,
                  class:  elementClasses,
            })
            this.cardCvcElement.mount("#card-cvc-element")
            this.cardNumberElement.on("change", this.setValidationError)
            this.cardExpiryElement.on("change", this.setValidationError)
            this.cardCvcElement.on("change", this.setValidationError)
        },
        // 入力バリデーション
        setValidationError(event) {
            this.stripeValidationErrorCardnumber = event.error && event.error.code === 'invalid_number' ? event.error.message : ''

            if (event.error) {
                switch (event.error.code) {
                    case 'invalid_expiry_year_past':
                        this.stripeValidationErrorExpired = event.error.message
                        break
                    case 'invalid_expiry_month_past':
                        this.stripeValidationErrorExpired = event.error.message
                        break
                    default:
                        this.stripeValidationErrorExpired = ''
                        break

                }
            } else {
                this.stripeValidationErrorExpired = ''
            }
        },
        // 変更するボタン
        async placeOrderButtonPressed(service_name) {
            this.success = 'loading'
            this.isLoading = true
            this.stripe.createToken(this.cardNumberElement).then(result => {
                if (result.error) {
                    this.stripeValidationError = result.error.message
                    this.success = false
                    this.isLoading = false
                } else {
                    let stripeObject = {
                        customer_id: this.customer_id,
                        source: result.token,
                    }
                    this.cardChangeStripe(service_name, stripeObject)
                }
            });
        },
        // カード変更処理
        async cardChangeStripe(service_name, stripeObject) {
            const changeCard = Config.FIREBASE_FUNCTIONS.httpsCallable('changePaymentCard')

            let card_change_data = {}
            card_change_data['stripe'] = stripeObject
            card_change_data['page_data'] = this.pagedata
            card_change_data['service_name'] = service_name

            let instance = this

            try {
                let result = await changeCard(card_change_data)
                await console.debug('[カード情報の変更結果]', result)

                if(result.data.status) {
                    await this.$swal({
                        title:'クレジットカード情報を変更しました',
                        confirmButtonText: '管理画面に戻る',
                        icon:'success',
                    }).then(() => {
                        instance.$emit('modalclose')
                    })
                    this.success = await true
                    this.isLoading = await false
                } else {
                    await this.$swal({
                        title:'カード変更がエラーとなりました',
                        html: result.data.error.code
                             + '<br>'
                             + result.data.error.message
                             + '<br>',
                        confirmButtonText: '管理画面に戻る',
                        icon:'error',
                    }).then(() => {
                        instance.$emit('modalclose')
                    })
                    this.success = await true
                    this.isLoading = await false
                }

            } catch (e){
                await this.$swal({
                    title:'カード情報変更がエラーとなりました',
                    text:'管理者に連絡してください:' + e.message,
                    confirmButtonText: '管理画面に戻る',
                    icon:'error',
                }).then(() => {
                    instance.$emit('modalclose')
                })
                this.success = await true
                this.isLoading = await false
            }
        },
        modalClose() {
            this.$emit('modalclose')
        }
    }
}
</script>

<style scoped>
.payment-form .comment {
    font-size:0.8rem;
   margin-bottom:10px;

}
.payment-form .error {
   color:red;

}
.payment-form {
    max-width: 400px;
    margin: 20px auto;
    border: 2px solid #ececec;
    padding: 12px;
    margin-bottom: 8px;
}
.payment-form h5 {
    margin: 10px 20px;
    padding: 10px;
    font-size: 1.5rem;
}
.payment-form h6 {
    margin-bottom: 5px;
    padding: 0px;
    text-align:center;
    font-size: 1.0rem;
}
.card-element {
    margin-top: 10px;
}
#card-number-element,
#card-expiry-element,
#card-cvc-element {
    background: white;
    padding: 5px;
    border: 2px solid #ececec;
    height: 30px;
}

.place-order-button-block {
    margin: 20px 0;
    text-align:center;
}

button {
  background-color: #23D160;
  color: white;
  border-radius: 4px;
  font-size: 20px;
  /* font-weight: 600; */
  border: 0;
  box-shadow: none;
  padding: 8px 24px;
}
</style>

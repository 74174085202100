<template>
  <transition name="modal" appear>
    <div class="modal modal-overlay" @click.self="$emit('close')">
      <div class="modal-window">
        <!-- <div v-if="type === 'checkout'"   class="modal-content">
          <Checkout :pagedata="pagedata" @modalclose="$emit('close')"/>
        </div> -->
        <div v-if="type === 'cardchange'" class="modal-content">
          <CardChange :pagedata="pagedata" @modalclose="$emit('close')"/>
        </div>
        <div v-if="type === 'qrlink'" class="modal-content">
          <QrLink :pagedata="pagedata" @modalclose="$emit('close')"/>
        </div>
        <div v-if="type === 'plandowngrade'" class="modal-content">
          <PlanDowngrade :pagedata="pagedata" :chargesdata="chargesdata" @modalclose="$emit('close')" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import Checkout from './stripe/Checkout.vue'
import CardChange from './stripe/CardChange.vue'
import QrLink from './share/QrLink.vue'
import PlanDowngrade from './stripe/PlanDowngrade.vue'

export default {
  props: ['pagedata','type','chargesdata'],
  components: {
    // Checkout,
    CardChange,
    QrLink,
    PlanDowngrade,
  },
  methods: {
  },
}
</script>

<style scoped>
.modal .modal-content {
    width: inherit;
    padding: 10px 20px;
}
.modal.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.modal-window {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.modal-footer {
  background: #ccc;
  padding: 10px;
  text-align: right;
}

.modal-enter-active, .modal-leave-active {
  transition: opacity 0.4s;
}
.modal-enter-active .modal-window, .modal-leave-active .modal-window {
  transition: opacity 0.4s, transform 0.4s;
}

.modal-leave-active {
  transition: opacity 0.6s ease 0.4s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;
}
.modal-enter .modal-window, .modal-leave-to .modal-window {
  opacity: 0;
  transform: translateY(-20px);
}
</style>

<template>
  <div>
    <b-notification aria-close-label="Close notification">
      サービスを利用したお客様の感想を５件まで掲載できます。<br>
      <br>
      ・サービスを受けたお客様の笑顔の写真<br>
      ・お客様とのツーショット写真<br>
      ・お客様の直筆の体験談の画像<br>
      などとともに、お客様の声（感想）の内容を掲載してください。<br>
      <br>
      ※タイトルの右側にある「︙」をクリックすると、順番の変更が可能です。
    </b-notification>

    <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/voc.png">

    <div class="field">
      <label class="label has-text-weight-bold" for="voc_title">{{ title.voc_title }}</label>
      <p class="pb-1">
        お客様の声を掲載する項目の見出しを入力してください。<br>
        例１）お客様の声<br>
        例２）施術体験談<br>
        <span class="has-text-danger">※未入力の場合、お客様の声は表示されません。</span>
      </p>
      <b-input id="voc_title" v-model="pageData['voc_title']" maxlength="10"></b-input>
    </div>

    <hr>

    <!--ここから-->
    <div v-if="updateFlg">
      <div class="content" v-for="key of 5" :key="key">
        <div class="move-panel">
          <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
          <template #trigger>
            <b-icon icon="dots-vertical"></b-icon>
          </template>
            <b-dropdown-item aria-role="listitem" @click="menuMove('voc', (key -1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('voc',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('voc',(key-1),'down')" :disabled="(key-1) === (pageData['voc'].length - 1)">一つ下に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('voc',(key-1),'down', true)" :disabled="(key-1) === (pageData['voc'].length - 1)">一番下に移動</b-dropdown-item>
          </b-dropdown>
        </div>

        <b-collapse
          aria-id="contentIdForA15y5"
          class=""
          animation="slide"
          v-model="isOpen">
          <template #trigger>
            <div
              class="panel-heading mb-3"
              role="button"
              aria-controls="contentIdForA15y5">
              <strong v-if="pageData['voc'][key-1]['title']">{{ pageData['voc'][key-1]['title']}}</strong>
              <strong v-else>お客様の声{{ key }}のタイトルを入力してください</strong>
            </div>
          </template>
          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'voc_title_' + key">お客様の声{{ key }}のタイトル </label>
            <p class="pb-1" v-if="(key-1) === 0">
              お客様の声を要約した内容を入力してください。<br>
              例１）苦手な運動もせずに、気がつくと-15kgに成功していました！<br>
              例２）腰痛がスッとなくなり身体が楽になりました。<br>
            </p>
            <b-input :id="'voc_title_' + key" v-model="pageData['voc'][key-1]['title']" maxlength="30"></b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'voc_data_' + key">お客様の声{{ key }}のデータ </label>
            <p class="pb-1" v-if="(key-1) === 0">
              地域名、お名前、年齢、職業などを入力してください。<br>
              例１）福岡市、山本様、25歳、営業職<br>
              例２）横浜市西区、田中様、45歳、会社員
            </p>
            <b-input :id="'voc_data_' + key" v-model="pageData['voc'][key-1]['data']" maxlength="30"></b-input>
          </div>

          <div v-if="updateFlg" class="field pb-3">
            <label class="label has-text-weight-bold" :for="'voc_image_' + key">&nbsp;お客様の声{{ key }}の画像</label>
            <p class="pb-1">
              「お客様の写真」や「お客様の直筆体験談」の画像を登録してください。<br>
              ※スマホからでも見やすいように横長のサイズ（横幅4：縦幅3 程度の比率）の画像がオススメです。
            </p>
            <div class="columns">
              <div v-if="!imageFile[key-1].isDeleted" class="column is-one-quarter">
                <img :src="pageData['voc'][key-1]['image']" width="100px">
              </div>
              <div class="column">
                <photo-resize :image-title="'voc_image'"
                :array-num="key-1" :doc-field-title="'voc'"
                :page-id="pageData['page_id']" :src="pageData['voc'][key-1]['image']"  @vocimage="imgUpdate" ref="vocimage"/>
              </div>
            </div>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'voc_detail_' + key">&nbsp;お客様の声{{ key }}の詳細</label>
            <p class="pb-1" v-if="(key-1) === 0">
              お客様の声の詳細を入力してください。<br>
              例）産後直前の妊婦体重になり、何をするのも面倒だった私が、苦手な運動もほとんどせず、気がつくと楽々-15kgに成功！リバウンドしない食生活が身につき、目標体重を維持しています！
            </p>
            <b-input :id="'voc_detail_' + key" v-model="pageData['voc'][key-1]['detail']" maxlength="150" type="textarea"></b-input>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- ここまで  -->

    <hr>

    <div class="field">
      <label class="label has-text-weight-bold" for="voc_viewmore">{{ title.voc_viewmore }}</label>
      <p class="pb-1">
        リンクさせたいウェブサイトURLを入力してください。<br>
        例）http://vegefru.org/#voc
      </p>
      <b-input id="voc_viewmore" v-model="doc['voc_viewmore']"></b-input>
    </div>
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  components: {
    'PhotoResize': PhotoResize,
  },
  methods: {
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile[imageFileData["arrayNum"]] = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      Object.keys(this.$refs.vocimage).map(async key => {
        this.$refs.vocimage[key].reset()
      })
    }
  },
  data () {
    return {
      updateFlg: true, // 再描画用
      isOpen : true,
      imageFile: Array.from(
        Array(5)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
    }
  }
}

</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.move-panel {
  position:relative;
  top:12px;
  left: -1%;
}
</style>

<template v-if='$store.state.user'>
  <div class="column is-4 is-offset-4">

    <h1 class="is-size-4 has-text-weight-bold has-text-centered">ログインメールアドレスの変更</h1>
    <p class="has-text-centered pb-3">ランディングページ作成サービス</p>

    <div class="field">
      <p class="pb-3">
        現在のメールアドレス：{{ currentmailaddress }}
      </p>
    </div>
    <b-field
      :type="errors.has('email') ? 'is-danger': ''"
      :message="errors.has('email') ? errors.first('email') : ''">
        <template slot="label">
          <span class="tag is-danger">必須</span>&nbsp;新しいメールアドレス
        </template>
      <div class="control">
        <b-input
            name="email"
            v-model="email"
            v-validate="'required|email'"
            data-vv-as="新しいメールアドレス"
            placeholder="新しいメールアドレス">
        </b-input>
      </div>

    </b-field>
    <b-field
      :type="errors.has('password') ? 'is-danger': ''"
      :message="errors.has('password') ? errors.first('password') : ''">
        <template slot="label">
          <span class="tag is-danger">必須</span>&nbsp;現在のパスワード
        </template>
      <div class="control">
        <b-input
            name="password"
            v-model="password"
            v-validate="'required'"
            data-vv-as="現在のパスワード"
            placeholder="現在のパスワード">
        </b-input>
      </div>

    </b-field>

    <div class="field">
      <p class="control">
        <button
          :disabled="processing || errors.any() || !isFormCompleted"
          class="button is-success"
          type="button"
          @click="mailchange"
        >
          変更を保存
        </button>
        <button
          :disabled="processing"
          class="button is-primary is-pulled-right"
          type="button"
          @click="toHome">キャンセル
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import Config from '../config.js'
import firebase from 'firebase/app'

export default {
  data () {
    // 変数設定
    let user = Config.FIREBASE_AUTH.currentUser
    let currentmailaddress

    // 現在の情報を取得
    if (user != null) {
      currentmailaddress = user.email;
    }

    return {
      currentmailaddress: currentmailaddress,
      email: '',
      password: '',
      processing: false,
      regex_email: /^[\x20-\x7e]*$/,
      customMessage:'メールアドレスのフォーマットが正しくありません'
    }
  },
  computed: {
    isFormCompleted: function() {
      if (!this.email || !this.password) {
        return false
      }
      return true
    },
  },
  methods: {
    toHome () {
      location.href = './'
    },
    async mailchange () {
      const isValid = await this.$validator.validate()
        if(!isValid) return

      if (this.processing) return
      this.processing = true
      let swal = this.$swal
      let user = Config.FIREBASE_AUTH.currentUser
      let currentPassword = this.password

      if (this.currentmailaddress === this.email) {
        swal.fire({
          icon:'warning',
          title: 'エラー',
          text: '現在のメールアドレスとは異なるメールアドレスを入力ください。',
          type: 'warning',
          allowOutsideClick: false
        })
        this.processing = false
        return
      }

      if (!currentPassword) {
        swal.fire({
          icon:'error',
          title: 'ログイン認証エラー',
          text: "ログイン状態でないとメールアドレス変更できません。",
          type: 'warning',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) {
            // 管理画面へ
            location.href = './'
          }
        })
      }

      // 再認証のパラメーター
      const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          currentPassword
      )

      let result = await user.reauthenticateWithCredential(credential).then(function() {
        // User re-authenticated.
        // console.log("認証成功")
        return true
      }).catch(function() {
        // console.log("再ログイン要求")
        swal.fire({
          title: 'エラー',
          text: "入力されたパスワードが間違っています",
          type: 'warning'
        })
        return false
      })

      if (!result) {
        this.processing = false
        return
      }

      result = await user.updateEmail(this.email).then(async function() {
        // Update successful.
        // console.log("メールアドレス変更")
        await Config.FIREBASE_AUTH.signOut()
        return true
      }).catch(function(error) {
        // An error happened.
        if ( error.message.indexOf('The email address is already in use by another account') !== -1) {
          error = 'すでに利用されているメールアドレスです！'
        }
        swal.fire({
          title: '変更に失敗しました',
          text: error,
          type: 'error',
        })
        return false
      })

      if (!result) {
        this.processing = false
        return
      }

      swal.fire({
        icon:'success',
        title: 'ログインメールアドレス',
        text: "変更しました",
        allowOutsideClick: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ログイン画面へ戻る'
      }).then((result) => {
        if (result.value) {
          // ログイン画面へ
          Config.FIREBASE_AUTH.signOut()
          location.href = './login'
        }
      })
    }
  }
}
</script>

function charaCountAndLimitStr(str) {
  let len = 0;
  for (let i = 0; i < str.length; i++) {
    str[i].match(/[ -~]/)
      ? (len += 1)
      : !str[i].match(/^[ｦ-ﾟ]*$/)
        ? (len += 2)
        : (len += 1);
    if (len > 25) {
      return false;
    }
  }
  return true;
}

function selectid(val, instance = null) {
  if (val === 'new') {
    let swal
    let doc = instance.draft
    if (instance) {
      swal = instance.$swal
    } else {
      swal = this.$swal
    }


    // select要素json取得
    let c = require('./const.js')
    let category_group = c.category_group
    let category = c.category
    let selected_category_group
    let selected_category

    swal.fire({
      title: '新規ページを作成します',
      input: 'select',
      text: '大業種を選択してください',
      footer: '該当の業種がない場合は、「その他」を選択してください',
      inputPlaceholder: '大業種選択',
      showCancelButton: true,
      cancelButtonText: 'キャンセル',
      confirmButtonText: '次へ',
      inputOptions: category_group,
      onAfterClose: function () {
        location.reload()
      },
      preConfirm: (input) => {
        if (!input) {
          swal.showValidationMessage(
            '大業種を選択してください'
          )
        }
      }
    }).then((result) => {
      if (result.value) {
        selected_category_group = result.value
        if (selected_category_group !== 'その他') {

          swal.fire({
            title: '新規ページを作成します',
            input: 'select',
            text: '小業種を選択してください',
            inputPlaceholder: '小業種選択',
            showCancelButton: true,
            cancelButtonText: 'キャンセル',
            confirmButtonText: '次へ',
            inputOptions: category[result.value],
            onAfterClose: function () {
              location.reload()
            },
            preConfirm: (input) => {
              if (!input) {
                swal.showValidationMessage(
                  '小業種を選択してください'
                )
              }
            }
          }).then((result) => {
            if (result.value) {
              selected_category = result.value

              swal.mixin({
                confirmButtonText: '次へ',
                allowOutsideClick: false,
              }).queue([
                {
                  input: 'text',
                  title: '新規ページを作成します',
                  html: '宣伝するサービス（15文字以内）' +
                    '<br>' +
                    '<br>' +
                    'ランディングページで宣伝するサービスの「一般的な名称」のことです。文章ではなく15文字以内の単語（1つのキーワード）だけを入力してください。' +
                    '<br>' +
                    '<br>' +
                    '例）歯列矯正、腰痛施術、経理代行、エアコンクリーニング、トイレリフォーム、トリミングなど',
                  showCancelButton: true,
                  cancelButtonText: 'キャンセル',
                  footer: '検索エンジンに認識させるキーワードになります',
                  inputAttributes: {
                    maxlength: 15,
                  },
                  onAfterClose: function () {
                    location.reload()
                  },
                  preConfirm: (input) => {
                    if (!input) {
                      swal.showValidationMessage(
                        'ページのキーワードを入力してください'
                      )
                    }
                  }
                },
                {
                  title: "新規ページを作成します",
                  html:
                    "事業所名（店舗名、会社名など）<br>半角25文字（全角12文字）以内" +
                    '<input id="swal-input1" class="swal2-input">' +
                    "<br>" +
                    "支店名（任意）<br>" +
                    "事業所が支店や支社の場合のみ入力してください" +
                    '<input id="swal-input2" class="swal2-input">',
                  showCancelButton: true,
                  cancelButtonText: 'キャンセル',
                  onAfterClose: function () {
                    location.reload()
                  },
                  preConfirm: () => {
                    const business_name = document.getElementById(
                      "swal-input1"
                    ).value;
                    const branch_name = document.getElementById(
                      "swal-input2"
                    ).value;
                    if (!business_name) {
                      swal.showValidationMessage(
                        "事業所名を入力してください"
                      );
                    }
                    if (!charaCountAndLimitStr(business_name)) {
                      swal.showValidationMessage(
                        "事業所名は半角25文字（全角12文字）以内で入力してください"
                      );
                    }
                    if (branch_name !== null && branch_name.length > 20) {
                      swal.showValidationMessage(
                        "支店名は半角、全角に関わらず20文字以内で入力してください"
                      );
                    }

                    return [
                      document.getElementById("swal-input1").value,
                      document.getElementById("swal-input2").value,
                    ];
                  }
                }
              ]).then((result) => {
                if (result.value) {
                  swal.fire({
                    title: '最終確認',
                    html:
                      '以下の内容でページを作成しますか？' + '<br>' + '<br>' +
                      '大業種: ' + selected_category_group + '<br>' +
                      '小業種: ' + selected_category + '<br>' +
                      '宣伝するサービス: ' + result.value[0] + '<br>' +
                      '事業所名: ' + result.value[1][0] +
                      (result.value[1][1] ? " " + result.value[1][1] : ""),
                    confirmButtonText: 'ページを作成',
                    showCancelButton: true,
                    allowOutsideClick: false,
                    // showLoaderOnConfirm: true,
                    cancelButtonText: 'キャンセル',
                  }).then((confirm) => {
                    if (confirm.value) {
                      doc['category_group'] = selected_category_group
                      doc['category'] = selected_category
                      doc['page_keyword'] = result.value[0]
                      doc['business_name'] = result.value[1][0]
                      doc['branch_name'] = result.value[1][1]
                      instance.submit(doc, val)
                    } else {
                      location.reload()
                    }
                  }) // confirm then
                } // result.value
              }) // swal mixin then
            } // end if
          }) // swal fire
        } else {
          swal.fire({
            title: '新規ページを作成します',
            input: 'text',
            text: '小業種を入力してください（15文字以内）',
            showCancelButton: true,
            cancelButtonText: 'キャンセル',
            confirmButtonText: '次へ',
            inputAttributes: {
              maxlength: 15,
            },
            onAfterClose: function () {
              location.reload()
            },
            preConfirm: (input) => {
              if (!input) {
                swal.showValidationMessage(
                  '小業種を入力してください'
                )
              }
            }
          }).then((result) => {
            if (result.value) {
              selected_category = result.value
              swal.mixin({
                confirmButtonText: '次へ',
                allowOutsideClick: false,
              }).queue([
                {
                  input: 'text',
                  title: '新規のページを作成します',
                  html: '宣伝するサービス（15文字以内）' +
                    '<br>' +
                    '<br>' +
                    'ランディングページで宣伝するサービスの「一般的な名称」のことです。文章ではなく15文字以内の単語（1つのキーワード）だけを入力してください。' +
                    '<br>' +
                    '<br>' +
                    '例）歯列矯正、腰痛施術、ピーリング、経理代行、エアコンクリーニング、トイレリフォーム、トリミングなど',
                  showCancelButton: true,
                  cancelButtonText: 'キャンセル',
                  footer: '検索エンジンに認識させるキーワードになります',
                  inputAttributes: {
                    maxlength: 15,
                  },
                  onAfterClose: function () {
                    location.reload()
                  },
                  preConfirm: (input) => {
                    if (!input) {
                      swal.showValidationMessage(
                        'ページのキーワードを入力してください'
                      )
                    }
                  }
                },
                {
                  title: "新規ページを作成します",
                  html:
                    "事業所名（店舗名、会社名など）<br>半角25文字（全角12文字）以内" +
                    '<input id="swal-input1" class="swal2-input">' +
                    "<br>" +
                    "支店名（任意）<br>" +
                    "事業所が支店や支社の場合のみ入力してください" +
                    '<input id="swal-input2" class="swal2-input">',
                  showCancelButton: true,
                  cancelButtonText: 'キャンセル',
                  onAfterClose: function () {
                    location.reload()
                  },
                  preConfirm: () => {
                    const business_name = document.getElementById(
                      "swal-input1"
                    ).value;
                    const branch_name = document.getElementById(
                      "swal-input2"
                    ).value;
                    if (!business_name) {
                      swal.showValidationMessage(
                        "事業所名を入力してください"
                      );
                    }
                    if (!charaCountAndLimitStr(business_name)) {
                      swal.showValidationMessage(
                        "事業所名は半角25文字（全角12文字）以内で入力してください"
                      );
                    }
                    if (branch_name !== null && branch_name.length > 20) {
                      swal.showValidationMessage(
                        "支店名は半角、全角に関わらず20文字以内で入力してください"
                      );
                    }

                    return [
                      document.getElementById("swal-input1").value,
                      document.getElementById("swal-input2").value,
                    ];
                  }
                }
              ]).then((result) => {
                    if (result.value) {
                      swal.fire({
                        title: '最終確認',
                        html:
                          '以下の内容でページを作成しますか？' + '<br>' + '<br>' +
                          '大業種: ' + selected_category_group + '<br>' +
                          '小業種: ' + selected_category + '<br>' +
                          '宣伝するサービス: ' + result.value[0] + '<br>' +
                          '事業所名: ' + result.value[1][0] +
                          (result.value[1][1] ? " " + result.value[1][1] : ""),
                        confirmButtonText: 'ページを作成',
                        showCancelButton: true,
                        allowOutsideClick: false,
                        // showLoaderOnConfirm: true,
                        cancelButtonText: 'キャンセル',
                      }).then((confirm) => {
                        if (confirm.value) {
                          doc['category_group'] = selected_category_group
                          doc['category'] = selected_category
                          doc['page_keyword'] = result.value[0]
                          doc['business_name'] = result.value[1][0]
                          doc['branch_name'] = result.value[1][1]
                          instance.submit(doc, val)
                        } else {
                          location.reload()
                        }
                      }) // confirm then
                    } // result.value
                  }) // swal mixin then
            } // end if
          }) // swal fire
        } // end if
      } // end if
    })
  }
}

export { selectid }

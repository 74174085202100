<template>
  <section class="ads-total-card">
    <div class="overview">
      <div class="overview-header has-text-centered has-background-link-light">
        <b-tooltip label="ページビュー数の略で、ページが閲覧された数" class="tooltip is-inline-block" type="is-dark" position="is-bottom" multilined animated>
          閲覧数<small>（PV数）</small>
        </b-tooltip>
      </div>
      <div class="has-text-centered overview-value">{{ reportData['total']['sessions'] }}</div>

      <div class="overview-contents">
        <div class="is-inline-block overview-contents-title">
          <b-tooltip label="閲覧数（PV数）のうち、ネット広告を経由した数" class="tooltip" type="is-dark" position="is-bottom" multilined animated>
            ネット広告
          </b-tooltip>
        </div>
        <div class="is-inline-block overview-contents-value">{{ reportData['channel']['paid_advertising'] }}</div>
      </div>
      <div class="overview-contents">
        <div class="is-inline-block overview-contents-title">
          <b-tooltip label="閲覧数（PV数）のうち、検索を経由した数（ネット広告を除く）" class="tooltip" type="is-dark" position="is-bottom" multilined animated>
            自然検索
          </b-tooltip>
        </div>
        <div class="is-inline-block overview-contents-value">{{ reportData['channel']['organic_search'] }}</div>
      </div>
      <div class="overview-contents">
        <div class="is-inline-block overview-contents-title">
          <b-tooltip label="閲覧数（PV数）のうち、URLの直接入力、メールのURL、チラシのQRコードなどを経由した数" class="tooltip" type="is-dark" position="is-bottom" multilined animated>
            メール、チラシ等
          </b-tooltip>
        </div>
        <div class="is-inline-block overview-contents-value">{{ reportData['channel']['direct_or_other'] }}</div>
      </div>
      <div class="overview-contents">
        <div class="is-inline-block overview-contents-title">
          <b-tooltip label="閲覧数（PV数）のうち、Facebook、Twitter、ブログなどのリンクを経由した数" class="tooltip" type="is-dark" position="is-bottom" multilined animated>
            ウェブサイト
          </b-tooltip>
        </div>
        <div class="is-inline-block overview-contents-value">{{ reportData['channel']['from_another_site'] }}</div>
      </div>
    </div>

    <div class="overview">
      <div class="overview-header has-text-centered has-background-link-light">
        <b-tooltip label="コンバージョン数の略で、問合せ（電話のボタンクリック、メールの送信、LINEのボタンクリック）につながった数です。" class="tooltip is-inline-block" type="is-dark" position="is-bottom" multilined animated>
          問合せ数<small>（CV数）</small>
        </b-tooltip>
      </div>
      <div class="has-text-centered overview-value">{{ reportData['total']['goalCompletionsAll'] }}</div>

      <div class="overview-contents">
        <div class="is-inline-block overview-contents-title">
          <b-tooltip label="問合せ数（CV数）のうち、メールが送信された数" class="tooltip" type="is-dark" position="is-top" multilined animated>
            WEB CV
          </b-tooltip>
        </div>
        <div class="is-inline-block overview-contents-value">{{ reportData['total']['goal1Completions'] }}</div>
      </div>
      <div class="overview-contents">
        <div class="is-inline-block overview-contents-title">
          <b-tooltip label="問合せ数（CV数）のうち、電話のボタンがクリックされた数" class="tooltip" type="is-dark" position="is-top" multilined animated>
            TEL CV
          </b-tooltip>
        </div>
        <div class="is-inline-block overview-contents-value">{{ reportData['total']['goal2Completions'] }}</div>
      </div>
      <div class="overview-contents">
        <div class="is-inline-block overview-contents-title">
          <b-tooltip label="問合せ数（CV数）のうち、LINEのボタンがクリックされた数" class="tooltip" type="is-dark" position="is-top" multilined animated>
            LINE CV
          </b-tooltip>
        </div>
        <div class="is-inline-block overview-contents-value">{{ reportData['total']['goal3Completions'] }}</div>
      </div>
    </div>

    <div class="overview">
      <div class="overview-header has-text-centered has-background-link-light">
        <b-tooltip label="コンバージョン率の略で、問合せにつながった割合です。問合せ数（CV数）÷閲覧数（PV数）で計算できます。" class="tooltip is-inline-block" type="is-dark" position="is-bottom" multilined animated>
          問合せ率<small>（CV率）</small>
        </b-tooltip>
      </div>
      <div class="has-text-centered overview-value">{{ reportData['total']['goalConversionRateAll'] }}</div>
    </div>

    <div class="readmore">
      <input id="ads_total_table_check" class="readmore-check" type="checkbox" @click="closeAdsTotalTable" />
      <!-- <div class="table-container readmore-content" ref="ads_total_table" v-on:mouseover="mouseOver($event, 'ads_total_table')" v-on:mouseleave="mouseLeave('ads_total_table')"> -->
      <div class="table-container readmore-content" ref="ads_total_table" v-on:mouseover="mouseOver($event, 'ads_total_table')">
        <table class="table is-bordered is-narrow is-hoverable is-striped">
          <thead>
            <tr>
              <th class="no-border"></th>
              <th>PV数</th>
              <th>CV数</th>
              <th>WEB CV</th>
              <th>TEL CV</th>
              <th>LINE CV</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in reportData['daily']" :key="key">
              <td class="has-text-centered">{{ item['date'] }}</td>
              <td class="has-text-right">{{ item['sessions'] | numberFormatter }}</td>
              <td class="has-text-right">{{ item['goalCompletionsAll'] | numberFormatter }}</td>
              <td class="has-text-right">{{ item['goal1Completions'] | numberFormatter }}</td>
              <td class="has-text-right">{{ item['goal2Completions'] | numberFormatter }}</td>
              <td class="has-text-right">{{ item['goal3Completions'] | numberFormatter }}</td>
            </tr>
            <tr>
              <td class="has-text-centered">合計</td>
              <td class="has-text-right">{{ reportData['total']['sessions'] | numberFormatter }}</td>
              <td class="has-text-right">{{ reportData['total']['goalCompletionsAll'] | numberFormatter }}</td>
              <td class="has-text-right">{{ reportData['total']['goal1Completions'] | numberFormatter }}</td>
              <td class="has-text-right">{{ reportData['total']['goal2Completions'] | numberFormatter }}</td>
              <td class="has-text-right">{{ reportData['total']['goal3Completions'] | numberFormatter }}</td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th class="no-border"></th>
              <th>PV数</th>
              <th>CV数</th>
              <th>WEB CV</th>
              <th>TEL CV</th>
              <th>LINE CV</th>
            </tr>
          </thead>
        </table>

        <button v-on:click="scrollElement($refs['ads_total_table'], 'left', 5, 140)" class="scroll-button" ref="left_button"><b-icon icon="arrow-left-bold" size="is-large"></b-icon></button>
        <button v-on:click="scrollElement($refs['ads_total_table'], 'right', 5, 140)" class="scroll-button" ref="right_button"><b-icon icon="arrow-right-bold" size="is-large"></b-icon></button>
      </div>
      <label class="readmore-label" for="ads_total_table_check"></label>
    </div>
  </section>
</template>

<script>
import Util from '../lib/util'

export default {
  props: {
    reportData: Object
  },
  filters: {
    yenFormatter: function (price) {
      return Util.yenFormatter(price)
    },
    numberFormatter: function (price) {
      return Util.numberFormatter(price)
    }
  },
  mounted: function() {
    this.$refs['left_button'].style.top = this.$refs['ads_total_table'].clientTop + 26 + 'px'
    if(Util.isScrollable(this.$refs['ads_total_table'], 'left')) {
      this.$refs['left_button'].style.left = this.$refs['ads_total_table'].scrollLeft + 'px'
      Util.switchCalss(this.$refs['left_button'], 'fadein-left', 'fadeout')
    }

    this.$refs['right_button'].style.top = this.$refs['ads_total_table'].clientTop + 26 + 'px'
    if(Util.isScrollable(this.$refs['ads_total_table'], 'right')) {
      this.$refs['right_button'].style.left = (this.$refs['ads_total_table'].scrollLeft + this.$refs['ads_total_table'].clientWidth - this.$refs['right_button'].clientWidth) + 'px'
      Util.switchCalss(this.$refs['right_button'], 'fadein-right', 'fadeout')
    }

    const that = this
    this.$refs.ads_total_table.onscroll = function(event) {
      if (Util.isScrollable(that.$refs['ads_total_table'], 'left')) {
        that.$refs['left_button'].style.left = event.currentTarget.scrollLeft + 'px'
        Util.switchCalss(that.$refs['left_button'], 'fadein-left', 'fadeout')
      } else {
        Util.switchCalss(that.$refs['left_button'], 'fadeout', 'fadein-left')
      }

      if (Util.isScrollable(that.$refs['ads_total_table'], 'right')) {
        that.$refs['right_button'].style.left = (event.currentTarget.scrollLeft + event.currentTarget.clientWidth - that.$refs['right_button'].clientWidth) + 'px'
        Util.switchCalss(that.$refs['right_button'], 'fadein-right', 'fadeout')
      } else {
        Util.switchCalss(that.$refs['right_button'], 'fadeout', 'fadein-right')
      }
    }
  },
  methods: {
    mouseOver(event, ref) {
      const pos = Util.getMousePositionWithinElement(event, event.currentTarget)

      this.$refs['left_button'].style.top = Util.getPositiveNumber(pos['y'] - (this.$refs['left_button'].clientHeight / 2)) + 'px'
      if(Util.isScrollable(this.$refs[ref], 'left')) {
        this.$refs['left_button'].style.left = event.currentTarget.scrollLeft + 'px'

        Util.switchCalss(this.$refs['left_button'], 'fadein-left', 'fadeout')
      }

      this.$refs['right_button'].style.top = Util.getPositiveNumber(pos['y'] - (this.$refs['right_button'].clientHeight / 2)) + 'px'
      if(Util.isScrollable(this.$refs[ref], 'right')) {
        this.$refs['right_button'].style.left = (event.currentTarget.scrollLeft + event.currentTarget.clientWidth - this.$refs['right_button'].clientWidth) + 'px'

        Util.switchCalss(this.$refs['right_button'], 'fadein-right', 'fadeout')
      }
    },
//    mouseLeave(ref) {
//      if(Util.isScrollable(this.$refs[ref], 'left')) {
//        Util.switchCalss(this.$refs['left_button'], 'fadeout', 'fadein-left')
//      }
//
//      if(Util.isScrollable(this.$refs[ref], 'right')) {
//        Util.switchCalss(this.$refs['right_button'], 'fadeout', 'fadein-right')
//      }
//    },
    closeAdsTotalTable() {
      if (!document.getElementById("ads_total_table_check").checked) {
        const pos = Util.getElementPos('report-board')
        window.scrollTo(0, pos.y);
      }
    },
    scrollElement : Util.scrollElement
  }
}
</script>

<style>
.ads-total-card .no-border {
  border: 0;
}

.table-container {
  position: relative;
  margin-top: 30px;
}
.scroll-button {
  position: absolute;
  color: #fff;
  background-color: #aaa;
  border-radius: 5px;
  opacity: 0;
  z-index: -1;
  border: none;
}

.fadein-right {
  animation: fadein-right 500ms ease 0s 1 normal forwards;
}
.fadein-left {
  animation: fadein-left 500ms ease 0s 1 normal forwards;
}
.fadeout {
  animation: fadeout 500ms ease 0s 1 normal forwards;
}
@keyframes fadein-right {
  0% { opacity: 0; z-index: -1; transform: translateX(-10px); }
  100% { opacity: 0.8; z-index: 1; transform: translateX(0); }
}
@keyframes fadein-left {
  0% { opacity: 0; z-index: -1; transform: translateX(10px); }
  100% { opacity: 0.8; z-index: 1; transform: translateX(0); }
}
@keyframes fadeout {
  0% { opacity: 0.8; z-index: 1; }
  100% { opacity: 0; z-index: -1; }
}

/* 続きを読む */
.readmore {
  position: relative;
}

.readmore-content {
  position: relative;
  overflow: hidden;
  height: 200px;

}
.readmore-content::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: '';

  height: 70px;
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 60%,
    #fff 100%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 60%,
    #fff 100%
  );
}

.readmore-label {
  z-index: 2;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  margin: auto;
  padding: 6px 0;
  border-radius: 2px;
  background: #1e88e5;
  color: #fff;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.readmore-label:after {
  content: '\025bc  レポートの続きを見る';
}

.readmore-label:hover {
  background: #42a5f5;
  color: #fff;
}

.readmore-check {
  display: none;
}
.readmore-check:checked ~ .readmore-label {
  bottom: -6px;
  display: block;
}
.readmore-check:checked ~ .readmore-label:after {
  content: '\025b2  閉じる';
}
.readmore-check:checked ~ .readmore-content {
  height: auto;
  padding-bottom: 25px;
}
.readmore-check:checked ~ .readmore-content::before {
  display: none;
}

.overview {
  width: 260px;
  margin: 20px auto;
  border-radius: 5px;
  border: solid 1px #ccc;
}
.overview-header {
  padding: 8px 0px;
  border-bottom: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}
.overview-value {
  padding: 8px 0px;
  font-weight: bold;
  font-size: x-large;
  border-bottom: 1px solid #ccc;
}
.overview-value:last-child {
  border-bottom: none;
}
.overview-contents {
  border-bottom: 1px solid #ccc;
}
.overview-contents:last-child {
  border-bottom: none;
}
.overview-contents-title {
  width: 80%;
  padding: 0.25em 0em 0.25em 0.5em;
  text-align: left;
  border-right: 1px solid #ccc;
}
.overview-contents-value {
  width: 20%;
  padding: 0.25em 0.5em 0.25em 0em;
  text-align: right;
}
</style>

import firebase from '@/firebase/firebaseInit'
import Util from './util'

export default class GaReport {
  static getReportData(collection, targetDate) {
//console.log("GaReport.getReportData")
    let reportData = []

    // 日ごと（当月分）------------------------------------------------
    reportData.push(firebase.firestore().collection(collection + '/ga/daily/rows')
            .where('date', '>=', Util.dateFormatter(targetDate, 'YYYY-MM-01'))
            .where('date', '<=', Util.dateFormatter(new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0), 'YYYY-MM-DD'))
            .orderBy('date').get())

    // 統計データを取得（チャネルごとのデータ）-----------------------------
    reportData.push(firebase.firestore().collection(collection + '/ga/channel/total')
            .doc(Util.dateFormatter(targetDate, 'YYYY-MM')).get())

    return reportData
  }

  static createReportData(dailyData, channelData, targetDate) {
    let reportData = {}

    let daily = []

    let totalSessions = 0
    let totalUsers = 0
    let totalGoal1Completions = 0
    let totalGoal2Completions = 0
    let totalGoal3Completions = 0
    let totalGoalCompletionsAll = 0

    if (dailyData.docs.length) {
      let lastDate = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0)
      for (let i = 1; i <= lastDate.getDate(); i++) {
        let docDate = Util.dateFormatter(targetDate, 'YYYY-MM-' + ('0' + i).slice(-2))
        let docData = {}

        dailyData.forEach(doc => {
          if (doc.data()['date'] == docDate) {
            docData = doc.data();
          }
        });

        // 日ごと（当月分）------------------------------------------------
        let data
        if (Object.keys(docData).length) {
          data = {
            'date': Util.dateFormatter(new Date(docDate), 'MM/DD(DAY)'),
            'sessions': parseInt(docData['sessions']),
            'users': parseInt(docData['users']),
            'goal1Completions': parseInt(docData['goal1Completions']),
            'goal2Completions': parseInt(docData['goal2Completions']),
            'goal3Completions': parseInt(docData['goal3Completions']),
            'goalCompletionsAll': parseInt(docData['goalCompletionsAll']),
            'goalConversionRateAll': (Math.round(docData['goalConversionRateAll'] * 100 * 100) / 100) + '%'
          }
        } else {
          data = {
            'date': Util.dateFormatter(new Date(docDate), 'MM/DD(DAY)'),
            'sessions': 0,
            'users': 0,
            'goal1Completions': 0,
            'goal2Completions': 0,
            'goal3Completions': 0,
            'goalCompletionsAll': 0,
            'goalConversionRateAll': '0.00%'
          }
        }
        daily.push(data)
        
        // 合計（当月分）--------------------------------------------------
        totalSessions += data['sessions']
        totalUsers += data['users']
        totalGoal1Completions += data['goal1Completions']
        totalGoal2Completions += data['goal2Completions']
        totalGoal3Completions += data['goal3Completions']
        totalGoalCompletionsAll += data['goalCompletionsAll']
      }
    }
    reportData['daily'] = daily

    let dispGoalConversionRateAll = ''
    if (totalGoalCompletionsAll >= 1 && totalSessions == 0) {
      dispGoalConversionRateAll = '-'
    } else if (totalGoalCompletionsAll == 0 && totalSessions == 0) {
      dispGoalConversionRateAll = '0%'
    } else {
      dispGoalConversionRateAll = (Math.round((totalGoalCompletionsAll / totalSessions) * 10 * 100) / 10) + '%' 
    }

    reportData['total'] = {}
    if (daily.length) {
      reportData['total'] = {
        'date': 'Total',
        'sessions': totalSessions,
        'users': totalUsers,
        'goal1Completions': totalGoal1Completions,
        'goal2Completions': totalGoal2Completions,
        'goal3Completions': totalGoal3Completions,
        'goalCompletionsAll': totalGoalCompletionsAll,
        'goalConversionRateAll': dispGoalConversionRateAll
      }
    }

    // 統計データを取得（チャネルごとのデータ）
    reportData['channel'] = {}
    if (channelData.exists) {
      reportData['channel'] = {
        'organic_search': parseInt(channelData.data()['organic_search']),                                           // 自然検索数
        'paid_advertising': parseInt(channelData.data()['display']) + parseInt(channelData.data()['paid_search']),  // 有料広告
        'from_another_site': parseInt(channelData.data()['referral']) + parseInt(channelData.data()['social']),     // 別サイトからの流入
        'direct_or_other': parseInt(channelData.data()['direct_or_other'])                                          // メール・チラシ等
      }
    }

    return reportData
  }
}

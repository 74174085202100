<template>
  <div class="column is-4 is-offset-4">

    <h1 class="is-size-4 has-text-weight-bold has-text-centered">パスワードのリセット</h1>
    <p class="has-text-centered pb-3">ランディングページ作成サービス</p>

    <div class="field">
      <p class="control">
        ログイン用のメールアドレスを入力してください。<br>
        パスワードをリセットするためのURLをお送りいたします。<br>
        <input class="input mt-1" type="email" placeholder="メールアドレス" v-model="email">
      </p>
    </div>
    <div class="field">
      <p class="control">
        <button :disabled="processing" class="button is-success" type="button" @click="passwordresetsend">メールを送信</button>
        <button :disabled="processing" class="button is-primary is-pulled-right" type="button" @click="toLogin">キャンセル</button>
      </p>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase'
import 'firebase/auth'

export default {
  data () {
    return {
      email: '',
      processing: false,
    }
  },
  methods: {
    toLogin () {
      location.href = 'login'
    },
    passwordresetsend () {
      if (this.processing) return
      this.processing = true

      let auth = firebase.auth()
      let emailAddress = this.email
      let swal = this.$swal
      let instance = this

      auth.sendPasswordResetEmail(emailAddress)
      .then(function() {
        swal('パスワードのリセット', "メールを送信しました", 'success')
        instance.processing = false
      }).catch(function(error) {
        if ( error.message == "There is no user record corresponding to this identifier. The user may have been deleted.") {
          swal('エラー', "入力されたメールアドレスは登録されていません", 'error')
        } else {
          swal('エラー', "送信失敗しました：" + error, 'error')
        }
        instance.processing = false
      })
    }
  }
}
</script>

<template>
  <div>
    <b-notification aria-close-label="Close notification">
      施術写真、ビフォーアフターの画像などを５件まで掲載できます。<br>
      <a href="https://r.ilb.net/case-ja" target="_blanc">事例の掲載ポイントを見る&nbsp;<span class="icon-external-link"></span></a><br>
      <br>
      ※タイトルの右側にある「︙」をクリックすると、順番の変更が可能です。
    </b-notification>

    <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/case.png">

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="case_title">{{ title.case_title }}</label>
      <p class="pb-1">
        事例を掲載する項目の見出しを入力してください。<br>
        例１）ダイエット成功実例<br>
        例２）慢性腰痛の改善事例<br>
        例３）フォトギャラリー<br>
        <span class="has-text-danger">※未入力の場合、事例は表示されません</span>
      </p>

      <b-input id="case_title" v-model="doc['case_title']" maxlength="10"></b-input>
    </div>

    <hr>

    <!--ここから-->
    <div v-if="updateFlg">
      <div class="content" v-for="key of 5" :key="key">
        <div class="move-panel">
          <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
          <template #trigger>
            <b-icon icon="dots-vertical"></b-icon>
          </template>
            <b-dropdown-item aria-role="listitem" @click="menuMove('case', (key -1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('case',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('case',(key-1),'down')" :disabled="(key-1) === (pageData['case'].length - 1)">一つ下に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('case',(key-1),'down', true)" :disabled="(key-1) === (pageData['case'].length - 1)">一番下に移動</b-dropdown-item>
          </b-dropdown>
        </div>

        <b-collapse
          aria-id="contentIdForA14y4"
          class=""
          animation="slide"
          v-model="isOpen">
          <template #trigger>
            <div
              class="panel-heading mb-3"
              role="button"
              aria-controls="contentIdForA14y4">
              <strong v-if="pageData['case'][key-1]['title']">{{ pageData['case'][key-1]['title']}}</strong>
              <strong v-else>事例{{ key }}のタイトルを入力してください</strong>
            </div>
          </template>
          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'case_title_' + key">事例{{ key }}のタイトル </label>
            <p class="pb-1" v-if="(key-1) === 0">
              利用されたサービス名や事例の概要を入力してください。<br>
              例１）骨盤ダイエットで、運動せずに-15kgの目標達成<br>
              例２）腰痛整体で、痛みで伸びなかった腰が伸びるように！<br>
            </p>
            <b-input :id="'case_title_' + key" v-model="pageData['case'][key-1]['title']" maxlength="30"></b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'case_data_' + key">事例{{ key }}のデータ</label>
            <p class="pb-1" v-if="(key-1) === 0">
              事例を説明するデータを入力してください。<br>
              例１）体重：69.5kg → 54.3kg（ -15.2kg ）、体脂肪率：-7.50%、ウエスト：-13.1cm<br>
              例２）40代、会社員、期間2ヶ月
            </p>
            <b-input :id="'case_data_' + key" v-model="pageData['case'][key-1]['data']" maxlength="150"></b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'case_image_' + key + '_1'">&nbsp;事例{{ key }}の画像１</label>
            <p class="pb-1">
              事例の画像を登録してください。<br>
              ※登録できる画像の枚数は、1つの事例につき2枚までとなります。<br>
              ※ビフォーアフターの場合、「ビフォーの画像」を掲載してください。
            </p>
            <div class="columns">
              <div class="column is-one-quarter">
                <img :src="pageData['case'][key-1]['image1']" width="100px">
              </div>
              <div class="column">
                <photo-resize :image-title="'case_image1'" :doc-field-image-title="'image1'"
                :array-num="key-1" :doc-field-title="'case'"
                :src="pageData['case'][key-1]['image1']" @caseimage="imgUpdate" ref="caseimage"/>
              </div>
            </div>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'case_image_' + key + '_2'">&nbsp;事例{{ key }}の画像２</label>
            <p class="pb-1">
              事例の画像を登録してください。<br>
              ※登録できる画像の枚数は、1つの事例につき2枚までとなります。<br>
              ※ビフォーアフターの場合、「アフターの画像」を掲載してください。
            </p>
            <div class="columns">
              <div v-if="!imageFile2[key-1].isDeleted" class="column is-one-quarter">
                <img :src="pageData['case'][key-1]['image2']" width="100px">
              </div>
              <div class="column">
                <photo-resize :image-title="'case_image2'" :doc-field-image-title="'image2'"
                :array-num="key-1" :doc-field-title="'case'"
                :src="pageData['case'][key-1]['image2']" @caseimage="imgUpdate" ref="caseimage"/>
              </div>
            </div>
          </div>

          <br>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'case_detail_' + key">&nbsp;事例{{ key }}の詳細</label>
            <p class="pb-1" v-if="(key-1) === 0">
              例）産後直前の妊婦体重になり、何をするのも面倒とおっしゃっていたお客様。苦手な運動をせず、-15kgのダイエットに成功。
            </p>
            <b-input :id="'case_detail_' + key" v-model="pageData['case'][key-1]['detail']" maxlength="150" type="textarea"></b-input>
          </div>
        </b-collapse>
      </div>
    </div>

    <hr>

    <div class="field">
      <label class="label has-text-weight-bold" for="case_viewmore">{{ title.case_viewmore }}</label>
      <p class="pb-1">
        リンクさせたいウェブサイトURLを入力してください。<br>
        例）http://vegefru.org/#case
      </p>
      <b-input id="case_viewmore" v-model="doc['case_viewmore']"></b-input>
    </div>
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  components: {
    'PhotoResize': PhotoResize,
  },
  methods: {
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      // image1,2で場合分け
      if(imageFileData.docFieldImageTitle === "image1") {
        this.imageFile1[imageFileData.arrayNum] = imageFileData
      } else if (imageFileData.docFieldImageTitle === "image2"){
        this.imageFile2[imageFileData.arrayNum] = imageFileData
      }
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData);
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function(){
      // 子コンポーネントのresetを実行
      Object.keys(this.$refs.caseimage).map(async key => {
        this.$refs.caseimage[key].reset()
      })
    }
  },
  data () {
    return {
      isOpen : true,
      updateFlg: true, // 再描画用
      imageFile1: Array.from(
        Array(5)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
      imageFile2: Array.from(
        Array(5)
        ).map(
          () => {
            return {
              isDeleted: false
            }
          }
        ),
    }
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.move-panel {
  position:relative;
  top:12px;
  left: -1%;
}
</style>

<template>
  <section class="row">
    <h5 class="has-text-centered">
      {{ pagedata['page_keyword'] }}（{{ pagedata['business_name'] }}）
    </h5>
    <p class="has-text-centered">
      <a :href="'https://jp.ilb.io/' + pagedata['page_id']" target="_blank">
        https://jp.ilb.io/{{ pagedata['page_id'] }}
      </a>
    </p>
    <p class="has-text-centered">
      <vue-qrcode id='qr-img' v-if="targetText" :value="targetText" :options="option" tag="img"></vue-qrcode>
    </p>
    <p class="has-text-centered">
    <b-button class="btn col s12 #e91e63 pink" type="is-success" @click="downloadImage()" >
      ダウンロード
    </b-button>
    </p>
  </section>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from 'axios'

export default {
  props: ['pagedata'],
  components:{
      VueQrcode
  },
  data() {
      return {
          targetText: "https://jp.ilb.io/" + this.pagedata['page_id'],
          option: {
              errorCorrectionLevel: "M",
              maskPattern: 0,
              margin: 5,
              scale: 2,
              width: 300,
              color: {
              dark: "#000000FF",
              light: "#FFFFFFFF"
              }
          }
      }
  },

  methods: {
    modalClose() {
      this.$emit('modalclose')
    },
    async downloadImage() {
      // 参考：https://r17n.page/2020/01/12/js-download-image-to-local/
      const src = document.querySelector("#qr-img").getAttribute("src");

      // ちゃんと例外処理したほうが良いけど省略
      const response = await axios.get(src, { responseType: "blob" });
      this.downloadImageToLocal(response);
    },
    downloadImageToLocal(response) {
      let dlLink = document.createElement("a");

      const dataUrl = URL.createObjectURL(response.data);
      dlLink.href = dataUrl;

      const fileName = 'QRコード：' + this.pagedata['page_keyword'] + '（' + this.pagedata['business_name'] + '）';
      dlLink.download = fileName;

      document.body.insertAdjacentElement("beforeEnd", dlLink);
      dlLink.click();
      dlLink.remove();

      setTimeout(function() {
        window.URL.revokeObjectURL(dataUrl);
      }, 1000);
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <section class="how-to-attract-customers">
    <!-- <div v-if="pageData['plan'] === 'free' || (pageData['plan'] === 'standard' && !getValue(chargesData, 'lp.payment_date'))" class="mt-4 border-danger">
      <div class="py-1 has-background-danger has-text-white has-text-centered has-text-weight-bold">
        さらなる集客アップのため<br />
        今すぐ有料プランをお試しください
      </div>

      <div class="p-4">
        <span class="has-text-weight-bold">■ 有料プランに変更するメリット</span><br />
        ・一覧ページ内で上位表示される：<span class="has-text-weight-bold access-count-font">閲覧数（PV数）アップ</span><br />
        ・ウェブページ内の他社広告が非表示になる：<span class="has-text-weight-bold closing-rate-font">問合せ率（CV率）アップ</span><br />
        <br />
        <span class="has-text-weight-bold">■ 利用料金と契約期間</span><br />
        月額 3,300円（税込）<br />
        契約期間の縛りはなく、1ヶ月だけのご利用も可能です。<br />
        <div>
          <b-button class="mt-4 button is-success is-fullwidth has-text-weight-bold" @click="openModal('checkout')">
          有料プランに変更する
          </b-button>
    -->

          <!-- コンポーネント Modal -->
    <!--

          <Modal class="change-plan qrcode-download" @close="closeModal" v-if="modal" :pagedata="pageData" :type="type" />
        </div>

        <div class="mt-4">
          <a href="https://r.ilb.net/lp-plan-ja"
            class="external-link"
            target="_blank"
          >
            無料プランと有料プランの違いを見る
            <span class="icon-external-link"></span>
          </a>
        </div>
      </div>
    </div>

    <hr v-if="pageData['plan'] === 'free' || (pageData['plan'] === 'standard' && !getValue(chargesData, 'lp.payment_date'))" />
    -->

    <div id="conversion" class="is-size-4 has-text-weight-bold">ランディングページで問合せ数（CV数）を増やす方法</div>
    <div>
      <br />
      ランディングページの問合せ数（CV数）は、<br />
      <span class="has-text-weight-bold">閲覧数（PV数）× 問合せ率（CV率）</span><br />
      で計算できます。<br />
      <br />
      問合せ数（CV率）を増やすためには、閲覧数（PV数）を増やして、問合せ率（CV率）を高める必要があります。<br />
      <br />
      <span class="has-text-weight-bold">※CV数</span>：コンバージョン数の略で、問合せ（電話のボタンクリック、メールの送信、LINEのボタンクリック）につながった数です。<br />
      <span class="has-text-weight-bold">※PV数</span>：ページビュー数の略で、ページが閲覧された数です。<br />
      <span class="has-text-weight-bold">※CV率</span>：コンバージョン率の略で、問合せにつながった割合です。CV数 ÷ PV数で計算できます。
    </div>
    <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/conversion.jpg" class="mt-4" />

    <div class="mt-4">
      <span class="has-text-weight-bold">ランディングページで問合せ数（CV数）を増やす方法</span><br />
      １．閲覧数（PV数）を増やす<br />
      ２．問合せ率（CV率）を高める<br />
      <br />
      具体的な方法は下記よりご確認ください。
    </div>

    <div id="access_count" class="mt-6 notification is-info is-light">
      <div id="page-view" class="is-size-4 has-text-weight-bold access-count-font">１．閲覧数（PV数）を増やす</div>
      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/page-view.jpg" class="mt-4" />
      <div>ランディングページの閲覧数（PV数）を増やすためには、Googleビジネスプロフィール、ウェブサイト、SNS、チラシ、ネット広告などを活用し、ページの存在を多くの方に知ってもらう必要があります。</div>
    </div>

    <div class="access-count-details-content">
      <!-- １）Googleビジネスプロフィールにランディングページのリンクを追加する	 -->
      <div id="link-gmb" class="is-size-6 has-text-weight-bold mt-4">
        １）Googleビジネスプロフィールにランディングページのリンクを追加する	
      </div>

      <div class="mt-1">Googleビジネスプロフィールの管理画面にある「ウェブサイト」という項目に、ランディングページのURLを入力することで閲覧数（PV数）を増やすことができます。</div>

      <div class="mt-4">Googleビジネスプロフィールを活用し、集客効果を高めるためのポイントをまとめた「設定マニュアル」は下記よりご確認ください。</div>
      <div>
        <a href="https://r.ilb.net/gmb-setting-ja" target="_blank">
          Googleマイビジネス設定マニュアルを見る
          <span class="icon-external-link"></span>
        </a>
      </div>

      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/gbp-link.jpg" class="mt-4" />

      <div v-if="!pageData['place_id']" class="mt-4">
        <div>Googleマイビジネスに未登録の場合は、下記より新規登録できます。</div>
        <div class="mt-1">
          <a href="https://r.ilb.net/gmb-register-ja" target="_blank">
            Googleマイビジネス 新規登録する方法を見る
            <span class="icon-external-link"></span>
          </a>
        </div>
      </div>

      <hr />

      <!-- ２）公式サイトやインデックスページなどにランディングページのリンクを追加する -->
      <div id="link-website" class="is-size-6 has-text-weight-bold mt-4">
        ２）公式サイトやインデックスページなどにランディングページのリンクを追加する
      </div>

      <div class="mt-1">御社が管理している公式サイトやインデックスページなどに、ランディングページのリンクを追加することで閲覧数（PV数）を増やすことができます。</div>
      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/url-link.jpg" class="mt-4" />

      <div class="mt-4">
        ■ インデックスページ作成サービスについて<br />
        <br />
        インデックスページ作成サービスは、宣伝用の「チラシ」と「ウェブページ」が無料で作成できるサービスです。<br />
        <br />
        お店ごとに「提供サービス」や「特長（選ばれる理由）」などを分かりやすく掲載することができるので、ホームページがわりにご利用いただけます。<br />
        <br />
        サービスの詳細・お申込方法は下記よりご確認ください。<br />
      </div>

      <a href="https://r.ilb.net/ip-ja" target="_blank" class="mt-4 button is-link is-outlined is-fullwidth">インデックスページの詳細を見る</a>

      <div class="mt-4">
        <a href="https://r.ilb.net/ip-lp-ja" target="_blank">
          インデックスページとランディングページの違いを見る
          <span class="icon-external-link"></span>
        </a>
      </div>

      <hr />

      <!-- ３）SNSにランディングページのリンクを追加する -->
      <div id="link-sns" class="is-size-6 has-text-weight-bold mt-4">
        ３）SNSにランディングページのリンクを追加する
      </div>

      <div class="mt-1">SNS（Facebook、Instagram、YouTube、Twitter、アメブロなど）のプロフィールや説明文などに、ランディングページのリンクを追加することで閲覧数（PV数）を増やすことができます。</div>
      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/sns.jpg" class="mt-4" />

      <hr />

      <!-- ４）SNSでランディングページのURLを紹介する -->
      <div id="share-sns" class="is-size-6 has-text-weight-bold mt-4">
        ４）SNSでランディングページのURLを紹介する
      </div>
      <div class="mt-1">下記リンクをクリックすると、ランディングページのURLを紹介できます。</div>

      <div class="mt-2">
        <span class="icon-facebook" style="color: #315096;"></span>
        <a :href="'https://www.facebook.com/share.php?u=https://jp.ilb.io/' + pageData['page_id']"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebookでシェアする
        </a>
      </div>

      <div class="mt-2">
        <span class="icon-twitter" style="color: #55acee;"></span>
        <a :href="'https://twitter.com/intent/tweet?text=' + pageData['page_keyword'] + 'の特長をまとめた新しいウェブページを作成しました。&url=https://jp.ilb.io/' + pageData['page_id'] + '&hashtags=' + pageData['page_keyword'] + ',' + pageData['category'] + ',' + pageData['city']"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitterでツイートする
        </a>
      </div>

      <div class="mt-2 mb-4">
        <span class="icon-line" style="color: #00c300;"></span>
        <a :href="'https://line.me/R/msg/text/?' + pageData['page_keyword'] + 'の特長をまとめた新しいウェブページを作成しました。%0D%0Ahttps://jp.ilb.io/' + pageData['page_id']"
          target="_blank"
          rel="noopener noreferrer"
        >
          LINEのタイムラインでシェアする
        </a>
      </div>

      <hr />

      <!-- ５）メルマガやLINE公式アカウントでランディングページのURLを紹介する -->
      <div id="share-message" class="is-size-6 has-text-weight-bold mt-4">
        ５）メルマガやLINE公式アカウントでランディングページのURLを紹介する
      </div>

      <div class="mt-1">メルマガの登録者やLINE公式アカウントの友だちなど、御社のことを知っている方に、ランディングページのURLを紹介することで閲覧数（PV数）を増やすことができます。</div>

      <div class="mt-4">下記の文章をコピーしてお使いいただけます。</div>
      <b-message type="is-info is-light">
        {{ pageData['page_keyword'] }}の特長をまとめた新しいウェブページを作成しました。<br />
        https://jp.ilb.io/{{ pageData['page_id'] }}
      </b-message>

      <b-message v-if="pageData['offer_title']" class="mt-4" type="is-info is-light">
        <div>
          <span v-if="pageData['offer_limited']">【{{ pageData['offer_limited'] }}】</span>{{ pageData['offer_title'] }}
        </div>
        <div v-if="pageData['offer_price']">{{ pageData['offer_price'] }}</div>
        https://jp.ilb.io/{{ pageData['page_id'] }}
      </b-message>

      <b-message v-if="pageData['line_id'] && pageData['line_title']" class="mt-4" type="is-info is-light">
        LINE友だち追加特典<br />
        {{ pageData['line_title'] }}<br />
        https://jp.ilb.io/{{ pageData['page_id'] }}
      </b-message>

      <hr />

      <!-- ６）QRコード付きのチラシを配布する -->
      <div id="flyer" class="is-size-6 has-text-weight-bold mt-4">
        ６）QRコード付きのチラシを配布する
      </div>

      <div class="mt-1">
        ランディングページのQRコードが入ったチラシを配布して、閲覧数（PV数）を増やせます。
      </div>

      <div class="mt-5">
        ■ チラシに使用するランディングページのQRコードは、下記のボタンをクリックして画像をダウンロードできます。<br />

        <b-button class="mt-4 button is-link is-fullwidth is-outlined mt-5" @click="openModal('qrlink')">
          QRコードを作成する
        </b-button>
        <!-- コンポーネント Modal -->
        <Modal class="change-plan qrcode-download" @close="closeModal" v-if="modal" :pagedata="pageData" :type="type" />
      </div>

      <div class="mt-5">
        ■ ランディングページのQRコードが入った「宣伝用チラシ」は、下記のボタンをクリックして作成できます。<br />
        「宣伝用チラシ」とは、オファーやお店の「特長（選ばれる理由）」を分かりやすくまとめた御社専用のチラシです。
      </div>

      <div class="mt-5">
        <div>
          <a :href="'http://app.ilb.io/flyer/lp/page.html?id=' + pageData['page_id']" target="_blank" class="button is-link is-outlined is-fullwidth">宣伝用チラシを作成する</a>
        </div>

        <div class="my-5">
          <a href="https://r.ilb.net/lp-flyer-page-ja" target="_blank">
            宣伝用チラシの作成方法と活用方法を見る
            <span class="icon-external-link"></span>
          </a>
        </div>

        <div class="mt-5">
          ＜宣伝用チラシの作成イメージ＞
        </div>

        <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/flyer-page.jpg" class="mt-" />
      </div>

      <hr />

      <!-- ７）ネット広告を利用する -->
      <div id="link-sns" class="is-size-6 has-text-weight-bold mt-4">
        ７）ネット広告を利用する
      </div>

      <div class="mt-1">
        ネット広告（Google、Yahoo!、Facebook広告など）を利用することでランディングページの閲覧数（PV数）を確実に増やすことができます。広告がクリックされた時だけ費用が発生するので無駄がありません。<br />
        <br />
        ローカルビジネス（地域密着の店舗・企業など）で利用するネット広告は、「Google広告」がオススメです。<br />
        <br />
        なぜなら、<br />
        ・商圏内の見込み客にだけ広告を表示できる<br />
        ・少ない予算から広告を試すことができる<br />
        など、広告費を有効に活用できる広告だからです。<br />
      </div>

      <div class="has-text-weight-bold mt-4">
        ■ Google広告（検索）
      </div>

      <div class="mt-1">
        商圏内でGoogleの検索結果の画面内に表示する広告です。自分で検索を行っているニーズの高いユーザーからの閲覧数を増やせます。
      </div>

      <div class="mt-4">
        ＜Google広告（検索）の表示イメージ＞
        <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/gs.jpg" />
      </div>

      <div class="has-text-weight-bold mt-4">
        ■ Google広告（ディスプレイ）
      </div>

      <div class="mt-1">
        商圏内で検索をしていないユーザーにも、ウェブページやアプリ内に表示される広告です。検索広告ではカバーできない潜在的なニーズのあるユーザーからの閲覧数を増やせます。
      </div>

      <div class="mt-4">
        ＜Google広告（ディスプレイ）の表示イメージ＞
        <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/gd.jpg" />
      </div>
    </div>

    <div id="closing_rate" class="mt-6 notification is-danger is-light">
      <div id="conversion-rate" class="is-size-4 has-text-weight-bold closing-rate-font">２．問合せ率（CV率）を高める</div>
      <img src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/conversion-rate.jpg" class="mt-4" />
      <div class="mt-4">問合せ率（CV率）を高めるためには、ランディングページの掲載内容を充実させる必要があります。</div>
    </div>

    <div class="closing-rate-details-content">
      <div>
        「掲載情報の入力・修正」から各項目を入力することで、ランディングページの掲載内容を追加できます。<br />
        各項目の入力状況は、下記よりチェックできます。
      </div>

      <div class="closing-rate-details-check-item">
        ※入力済みの項目は、チェック「<img src="@/assets/check-square.svg" style="opacity: 0.2;" />」と「<span style="text-decoration: line-through;">打ち消し線</span>」で表示されています。<br />
        例）<br />
        <img src="@/assets/square.svg" /><span class="has-text-weight-bold">未入力の項目</span><br />
        <img src="@/assets/check-square.svg" style="opacity: 0.2;" /><span class="item-entered has-text-weight-bold">入力済みの項目</span>
      </div>

      <div v-for="item in getIncreaseClosingRateItems" :key="item.title">
        <hr class="mt-5"/>

        <div :id="item.id" class="is-size-6 has-text-weight-bold mt-4">{{ item.title }}</div>

        <div class="mt-2">
          <template v-for="topMsg in item.topMsgs">
            <div :class="topMsg.class"  :key="topMsg.msg">{{ topMsg.msg }}</div>
          </template>
        </div>

        <div v-if="Object.keys(item.pointLink).length" class="mt-2">
          <a :href="item.pointLink.url"
            class="external-link"
            target="_blank"
          >
            {{ item.pointLink.text }}
            <span class="icon-external-link"></span>
          </a>
        </div>

        <img v-if="item.imgUrl" :src="item.imgUrl" class="mt-3" />

        <div class="mt-4 closing-rate-details-check-item">
          <template v-for="targetItem in item.targetItems">
            <div v-if="checkPageDataItem(targetItem)" :key="targetItem">
              <img src="@/assets/check-square.svg" style="opacity: 0.2;" />
              <span class="item-entered">{{ getPageDataItemName(targetItem) }}</span>
            </div>
            <div v-else :key="targetItem">
              <img src="@/assets/square.svg" />
              <span>{{ getPageDataItemName(targetItem) }}</span>
            </div>
          </template>
        </div>

        <div v-if="!checkAllPageDataItem(item.targetItems)" class="mt-5">
          <div class="button is-link is-outlined is-fullwidth" @click="$router.push('/?active_tab=' + item.tabNo)">{{ item.label }}</div>

          <div v-if="Object.keys(item.infoLink).length" class="mt-5">
            {{ item.infoLink.label }}
            <br />
            <a :href="item.infoLink.url"
              class="external-link"
              target="_blank"
            >
              {{ item.infoLink.text }}
              <span class="icon-external-link"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Util from '../lib/util'

import Modal from '../../Modal.vue'
import Config from '../../../config.js'
import {loadStripe} from '@stripe/stripe-js'

export default {
  components: { Modal },
  props: {
    pageData: Object,
    chargesData: Object,
    itemNames: Object,
  },
  data () {
    return {
      modal: false,
      type:'',
      payment_date: '',
      last_payment_date: ''
    }
  },
  computed: {
    getIncreaseClosingRateItems() {
      const items = []

      items.push(
        {
          id: 'gmb',
          label: 'Googleビジネスプロフィールを選択する',
          title: '１）Googleビジネスプロフィール（Place ID）',
          imgUrl: '',
          targetItems: ['place_id'],
          topMsgs: [
            { msg: 'Googleビジネスプロフィール（Place ID)を選択することで、事業所名、電話番号、郵便番号、住所、営業時間が自動で入力されます。また、Googleビジネスプロフィールに投稿されたクチコミがランディングページに掲載されます。', class: '' },
          ],
          pointLink: {},
          infoLink: {
            label: 'Googleビジネスプロフィールに未登録の場合は、下記より新規登録できます',
            text: 'Googleビジネスプロフィール 新規登録する方法',
            url: 'https://r.ilb.net/gmb-register-ja',
          },
          tabNo: '0',
        }
      )

      items.push(
        {
          id: 'information',
          label: '基本情報を入力する',
          title: '２）基本情報の入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/information.jpg',
          targetItems: [
            'station',
            'access',
            'opening',
            'opening_notice',
            'business_image',
            'payment',
            'parking',
            'business_summary',
            'google_calendar_id',
            'contact_mail_notice',
            'contact_mail_guide',
            'contact_url',
            'contact_text',
            'disclaimer',
          ],
          topMsgs: [
            { msg: '最寄り駅や御社の営業日、営業時間など基本的な情報を掲載できます。', class: '' },
          ],
          pointLink: {},
          infoLink: {},
          tabNo: '1',
        }
      )

      items.push(
        {
          id: 'offer',
          label: 'オファーを入力する',
          title: '３）オファーの入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/offer.jpg',
          targetItems: [
            'offer_catchphrase',
            'offer_limited',
            'offer_title',
            'offer_price',
            'offer_image',
            'offer_detail',
            'offer_movie',
            'offer_notice',
            'offer_link_url',
            'offer_link_text',
          ],
          topMsgs: [
            { msg: 'ページを見た方が予約・問合せをするきっかけとなるサービスや特典などを掲載できます。', class: '' },
          ],
          pointLink: {
            text: 'オファーの掲載ポイントを見る',
            url: 'https://r.ilb.net/ip-offer-ja',
          },
          infoLink: {},
          tabNo: '2',
        }
      )

      items.push(
        {
          id: 'line',
          label: 'LINEを入力する',
          title: '４）LINEの入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/line-friend.jpg',
          targetItems: [
            'line_id',
            'line_title',
            'line_image',
            'line_detail',
          ],
          topMsgs: [
            { msg: 'LINE公式アカウントのIDを入力すると、LINEで問合せの受付ができます。また、友だち追加特典も掲載できます。', class: '' },
          ],
          pointLink: {
            text: 'LINE友だち追加特典の掲載ポイントを見る',
            url: 'https://r.ilb.net/ip-line-offer-ja',
          },
          infoLink: {},
          tabNo: '3',
        }
      )

      items.push(
        {
          id: 'staff',
          label: 'スタッフ紹介を入力する',
          title: '５）スタッフ紹介の入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/staff.jpg',
          targetItems: [
            'staff_position',
            'staff_name',
            'staff_qualification',
            'staff_career',
            'staff_image',
            'staff_message',
            'staff_viewmore',
          ],
          topMsgs: [
            { msg: '御社スタッフのプロフィールを掲載できます。', class: '' },
          ],
          pointLink: {},
          infoLink: {},
          tabNo: '4',
        }
      )

      items.push(
        {
          id: 'case',
          label: '事例を入力する',
          title: '６）事例の入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/case.jpg',
          targetItems: [
            'case_title',
            'case0_title',
            'case0_data',
            'case0_image1',
            'case0_image2',
            'case0_detail',
            'case1_title',
            'case1_data',
            'case1_image1',
            'case1_image2',
            'case1_detail',
            'case2_title',
            'case2_data',
            'case2_image1',
            'case2_image2',
            'case2_detail',
            'case3_title',
            'case3_data',
            'case3_image1',
            'case3_image2',
            'case3_detail',
            'case4_title',
            'case4_data',
            'case4_image1',
            'case4_image2',
            'case4_detail',
            'case_viewmore',
          ],
          topMsgs: [
            { msg: '施術写真、ビフォーアフターの画像などを5件まで掲載できます。', class: '' },
          ],
          pointLink: {
            text: '事例の掲載ポイントを見る',
            url: 'https://r.ilb.net/ip-case-ja',
          },
          infoLink: {},
          tabNo: '5',
        }
      )

      items.push(
        {
          id: 'feature',
          label: '特長を入力する',
          title: '７）特長の入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/feature.jpg',
          targetItems: [
            'feature0_title',
            'feature0_image',
            'feature0_detail',
            'feature1_title',
            'feature1_image',
            'feature1_detail',
            'feature2_title',
            'feature2_image',
            'feature2_detail',
            'feature3_title',
            'feature3_image',
            'feature3_detail',
            'feature4_title',
            'feature4_image',
            'feature4_detail',
          ],
          topMsgs: [
            { msg: 'サービスの特長（選ばれる理由）を5件まで掲載できます。', class: '' },
          ],
          pointLink: {
            text: '特長（選ばれる理由）の掲載ポイントを見る',
            url: 'https://r.ilb.net/lp-feature-ja',
          },
          infoLink: {},
          tabNo: '6',
        }
      )

      items.push(
        {
          id: 'service',
          label: '提供サービスを入力する',
          title: '８）提供サービスの入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/service.jpg',
          targetItems: [
            'service_title',
            'service0_tag',
            'service0_title',
            'service0_price',
            'service0_image',
            'service0_detail',
            'service0_url',
            'service1_tag',
            'service1_title',
            'service1_price',
            'service1_image',
            'service1_detail',
            'service1_url',
            'service2_tag',
            'service2_title',
            'service2_price',
            'service2_image',
            'service2_detail',
            'service2_url',
            'service3_tag',
            'service3_title',
            'service3_price',
            'service3_image',
            'service3_detail',
            'service3_url',
            'service4_tag',
            'service4_title',
            'service4_price',
            'service4_image',
            'service4_detail',
            'service4_url',
            'service_viewmore',
          ],
          topMsgs: [
            { msg: 'ランディングページで宣伝する内容に関連したサービスを5件まで掲載できます。', class: '' },
            { msg: '※関連しないサービスが入っていると、ページからの問合せや予約が減少しますのでご注意ください。', class: '' },
          ],
          pointLink: {},
          infoLink: {},
          tabNo: '7',
        }
      )

      items.push(
        {
          id: 'voc',
          label: 'お客様の声を入力する',
          title: '９）お客様の声の入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/voice.jpg',
          targetItems: [
            'voc_title',
            'voc0_title',
            'voc0_data',
            'voc0_image',
            'voc0_detail',
            'voc1_title',
            'voc1_data',
            'voc1_image',
            'voc1_detail',
            'voc2_title',
            'voc2_data',
            'voc2_image',
            'voc2_detail',
            'voc3_title',
            'voc3_data',
            'voc3_image',
            'voc3_detail',
            'voc4_title',
            'voc4_data',
            'voc4_image',
            'voc4_detail',
            'voc_viewmore',
          ],
          topMsgs: [
            { msg: 'サービスを利用したお客様の感想を5件まで掲載できます。', class: '' },
          ],
          pointLink: {},
          infoLink: {},
          tabNo: '8',
        }
      )

      items.push(
        {
          id: 'faq',
          label: 'よくある質問を入力する',
          title: '１０）よくある質問の入力状況',
          imgUrl: 'https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/report/faq.jpg',
          targetItems: [
            'qa0_question',
            'qa0_answer',
            'qa0_movie',
            'qa1_question',
            'qa1_answer',
            'qa1_movie',
            'qa2_question',
            'qa2_answer',
            'qa2_movie',
            'qa3_question',
            'qa3_answer',
            'qa3_movie',
            'qa4_question',
            'qa4_answer',
            'qa4_movie',
          ],
          topMsgs: [
            { msg: 'お客様からよく聞かれる質問と回答を5件まで掲載できます。', class: '' },
          ],
          pointLink: {},
          infoLink: {},
          tabNo: '9',
        }
      )

      return items
    },
  },
  methods: {
    checkPageDataItem(item) {
      if (
        this.pageData[item] || 
        this.pageData['case'] ||
        this.pageData['feature'] ||
        this.pageData['service'] ||
        this.pageData['voc'] ||
        this.pageData['qa']
      ) {
        if (item === 'opening') {
          return Object.keys(this.pageData['opening']).find(key => this.pageData['opening'][key].open_day)
        } else if (
          item.match(/case[0-9]/) ||
          item.match(/feature[0-9]/) ||
          item.match(/service[0-9]/) ||
          item.match(/voc[0-9]/) ||
          item.match(/qa[0-9]/)
        ) {
          const words = item.split('_')
          const firstWord = words[0].replace(/[0-9]/g, '')
          const firstNo = Number(words[0].replace(/[^0-9]/g, ''))
          return this.pageData[firstWord][firstNo][words[1]]
        } else if (item.match(/service_keywords/)) {
          const no = Number(item.replace(/[^0-9]/g, ''))
          return this.pageData['service_keywords'][no]
        } else if (Array.isArray(this.pageData[item])) {
          return Object.keys(this.pageData[item]).length
        } else {
          return this.pageData[item]
        }
      }
    },
    checkAllPageDataItem(items) {
      for (const item of items) {
        if (!this.checkPageDataItem(item)) {
          return false
        }
      }

      return true
    },
    getPageDataItemName(item) {
      if (
        item.match(/case[0-9]/) ||
        item.match(/feature[0-9]/) ||
        item.match(/service[0-9]/) ||
        item.match(/voc[0-9]/) ||
        item.match(/qa[0-9]/)
      ) {
        const words = item.split('_')
        const firstWord = words[0].replace(/[0-9]/g, '')
        const firstNo = Number(words[0].replace(/[^0-9]/g, ''))
        if (this.itemNames[firstWord]) {
          return this.itemNames[firstWord][firstNo][words[1]]
        }
      } else if (item.match(/service_keywords/)) {
        const no = Number(item.replace(/[^0-9]/g, ''))
        return this.itemNames['service_keywords'][no]
      } else if (item === 'place_id') {
        return 'Googleマイビジネス（Place ID）の選択'
      } else {
        return this.itemNames[item]
      }
    },
    getValue(obj, key, undefinedValue) {
      return Util.getValue(obj, key, undefinedValue)
    },
    openModal(type) {
      // 自分で作るパターン
      this.modal = true
      this.type = type
    },
    closeModal() {
      this.modal = false
      this.$emit('modalclose')
      console.debug("[modalclose]")
    },
  },
  async mounted() {
    this.stripe = await loadStripe(Config.PUBLIC_KEY)
  },
}
</script>

<style>
.border-danger {
  border: 3px solid #ff3860;
  border-radius: 5px;
}

.border-success {
  border: 3px solid #23d160;
  border-radius: 5px;
}

.how-to-attract-customers {
  padding: 0px 5px;
  max-width: 720px;
  margin: 0px auto;
  font-size: 16px;
  white-space: normal;
}

.how-to-attract-customers img {
  width: 640px;
}

.closing-rate-font {
  color: #d74f53;
}

.access-count-font {
  color: #2f61cf;
}

.item-entered {
  text-decoration: line-through;
  color: #ccc;
}

.external-link {
  color: #7957d5 !important;
  text-decoration: none !important;
}


/* 入力状況を確認する */
.closing-rate-details-content {
  margin-top: 20px;
}

.closing-rate-details-content img {
  width: 640px;
  padding: 5px;
}

.closing-rate-details-check-item img {
  border: none;
  width: 20px;
  height: 20px;
  padding: 0px;
  margin-right: 5px;
  vertical-align: text-top;
}

/* 施策の詳細を見る */
.access-count-details-content {
  margin-top: 20px;
}

.access-count-details-content img {
  width: 640px;
  padding: 5px;
}

.change-plan {
  color: #4a4a4a;
}
.change-plan a {
  color: #7957d5 !important;
  cursor: pointer !important;
  text-decoration: none !important;
}
.change-plan .field {
  margin: unset !important;
  width: unset !important;
  text-align: unset !important;
}
.change-plan .field:not(:last-child) {
  margin-bottom: 0.75rem !important;
}

.qrcode-download img {
  width: auto;
  border: unset;
  padding: unset;
}
</style>

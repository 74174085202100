<template>
  <div>
    <b-notification aria-close-label="Close notification">
      LINE公式アカウントのIDを入力すると、LINEで問合せの受付ができます。<br>
      <br>
      友だち追加特典の案内も表示できます。（「LINE公式アカウントのID」、「LINE友だち追加特典のタイトル」の入力が必要です）<br>
      さらに、「メニュー > チラシの作成」から「LINE友だち募集用チラシ」をダウンロードできます。<br>
      <a href="https://r.ilb.net/line-offer-ja" target="_blanc">LINE友だち追加特典&nbsp;掲載のポイントを見る&nbsp;<span class="icon-external-link"></span></a>
    </b-notification>

    <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/line.png">

    <div class="field pb-3 notification is-info is-light">
      <label class="label has-text-weight-bold" for="line_id">{{ title.line_id }}</label>
      <p class="pb-1">
        LINE公式アカウントのIDを入力してください。（@は不要です）<br>
        例）vegefru-kitchen
      </p>
      <b-input id="line_id" v-model="pageData['line_id']" class="pb-3"></b-input>

      <div v-if="pageData['line_id'] == ''" class="pb-3">
        <p class="pb-1">
          <a href="https://r.ilb.net/line-id-ja" target="_blanc">LINE公式アカウントのIDを確認する方法を見る&nbsp;<span class="icon-external-link"></span></a>
        </p>
        <p>LINE公式アカウントがない場合は、下記より開設方法をご確認ください。</p>
        <a href="https://www.linebiz.com/jp/entry/" target="_blanc" class="button is-primary">開設方法を確認する</a>
      </div>
      <div v-else class="pb-3">
        入力したLINE公式アカウントを確認するためには、下記URLをクリックしてください。<br>
        <a :href="'https://line.me/R/ti/p/%40' + pageData['line_id']" target="_blank">
          https://line.me/R/ti/p/%40{{ pageData['line_id'] }}/
        </a>
      </div>
    </div>

    <p class="">
      LINE公式アカウントで問合せを受け付ける場合は、LINE公式アカウントの管理画面で、「設定 ＞ 応答設定 ＞ 応答モード」を「チャット」にしておく必要があります。<br>
      <a href="https://www.linebiz.com/jp/column/technique/20190830/" target="_blanc">LINEのチャット機能の利用方法を見る&nbsp;<span class="icon-external-link"></span></a>
    </p>

    <hr>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="line_title">
        {{ title.line_title }}
      </label>
      <p class="pb-1">
        <span>友だち追加特典をランディングページに掲載する場合、特典のタイトルを入力してください。<br></span>
        例１）スムージ１杯（400円相当）無料プレゼント<br>
        例２）化粧品サンプル プレゼント（1,000円相当）<br>
        例３）腰痛整体 4,500円 → 割引価格1,980円（税込）
      </p>
      <b-input id="line_title" v-model="pageData['line_title']" maxlength="30"></b-input>
    </div>

    <div v-if="updateFlg" class="field pb-3">
      <label class="label has-text-weight-bold">{{ title.line_image }}</label>
      <p class="pb-1">
        特典内容のイメージ画像を登録してください。<br>
        ※スマホからでも見やすいように横長のサイズ（横幅4：縦幅3 程度の比率）で文字が入っていない画像がオススメです。<br>
      </p>
      <div class="columns">
        <div v-if="!imageFile.isDeleted" class="column is-one-quarter">
          <img :src="pageData['line_image']" width="100px">
        </div>
        <div class="column">
          <photo-resize  :image-title="'line_image'" :src="pageData['line_image']"  @lineimage="imgUpdate" ref="lineimage"/>
        </div>
      </div>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="line_detail">{{ title.line_detail }}</label>
      <p class="pb-1">
        友だち追加時に提供する特典内容を入力してください。<br>
        <br>
        例）オーガニック（有機）の食材だけを使用した当店人気の「スムージー」を１杯無料でプレゼント！３つの味（豆乳バナナ、小松菜バナナ、ストロベリーバナナ）からお選びいただけます。
      </p>
      <b-input id="line_detail" v-model="pageData['line_detail']" maxlength="100" type="textarea"></b-input>
    </div>
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import PhotoResize from '@/components/PhotoResize.vue'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    }
  },
  components: {
    'PhotoResize': PhotoResize
  },
  methods: {
    // photoResize→親（Main.vue）へ渡す画像用配列：imageFileData
    imgUpdate: function (imageFileData) {
      this.imageFile = imageFileData
      // 子コンポーネント画面再描画用
      this.updateFlg = Math.random()
      this.$emit('imgUpdate', imageFileData)
    },
    // 親（Main.vue）→photoResize.reset(画面上の画像表示削除させる)
    reset : function() {
      this.$refs.lineimage.reset()
    }
  },
  data () {
    return {
      imageFile:{},
      updateFlg: true // 再描画用
    }
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.is-clearfix::after {
  display: contents !important;
}
</style>

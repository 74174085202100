<template>
  <div>
    <b-notification aria-close-label="Close notification">
      Googleビジネスプロフィールのクチコミ、評価の公開設定ができます。<br>
      <br>
      <div v-if="doc['place_id']">
        クチコミ件数：{{ pageData['review_number'] }}<br>
        クチコミの平均評価：{{ pageData['review_rating'] }}<br>
        <a :href="'https://search.google.com/local/reviews?placeid=' + pageData['place_id']" target="_blank">
          クチコミの一覧を見る&nbsp;<span class="icon-external-link"></span>
        </a>
      </div>
      <div v-else>
        クチコミは、「必須項目」のタブから、御社のGoogleビジネスプロフィール（Place ID）を選択することで表示できます。<br>
      </div>
      <br>
      ※「メニュー ＞ チラシの作成」から「クチコミ募集用チラシ」が無料でダウンロードできます。
    </b-notification>

    <div class="field">
      <label class="label has-text-weight-bold" for="review_display">{{ title.review_display }}</label>
      <b-switch id="review_display"
        v-model="pageData['review_display']"
        >クチコミ、評価を公開する
      </b-switch>
    </div>

    <div v-if="isReviewUpdateTarget()" class="field pb-3 notification is-danger is-light">
      <p class="pb-1">
        Googleビジネスプロフィールに新規投稿されたクチコミをページに反映するためには、下記ボタンをクリックしてください。Googleビジネスプロフィールから関連度の高い順に５件のクチコミを取得します。（１日１回まで）<br>
        ※毎月５日に自動で取得して更新します。その際、各クチコミの公開・非公開の設定項目はリセットされます。
      </p>
      <p class="pb-1">クチコミの更新日：{{ pageData['review_date'] }}</p>
      <button @click="updateReviewData(pageData['place_id'])" class="button is-primary">クチコミを更新する</button>
    </div>

    <div class="field pt-3" v-if="doc['place_id']">
      <label class="label has-text-weight-bold" for="review1_display">各クチコミの公開</label>

      <p class="has-text-danger pb-3">
        更新した場合、評価３以上のクチコミが自動公開となります。各クチコミ内容を確認し、公開・非公開の選択をしてください。
      </p>

      <div v-if="pageData['review1_rating']">
        <b-switch id="review1_display"
          class="pt-1"
          v-model="pageData['review1_display']"
          >このクチコミを公開する
        </b-switch>
        <p>
          投稿者名：{{ pageData['review1_name'] }}
        </p>
        <p>
          評価：{{ pageData['review1_rating'] }}
        </p>
        <p>
          {{ pageData['review1_text'] }}
        </p>
      </div>

      <div v-if="pageData['review2_rating']">
        <hr>
        <b-switch id="review2_display"
          class="pt-1"
          v-model="pageData['review2_display']"
          >このクチコミを公開する
        </b-switch>
        <p>
          投稿者名：{{ pageData['review2_name'] }}
        </p>
        <p>
          評価：{{ pageData['review2_rating'] }}
        </p>
        <p>
          {{ pageData['review2_text'] }}
        </p>
      </div>

      <div v-if="pageData['review3_rating']">
        <hr>
        <b-switch id="review3_display"
          class="pt-1"
          v-model="pageData['review3_display']"
          >このクチコミを公開する
        </b-switch>
        <p>
          投稿者名：{{ pageData['review3_name'] }}
        </p>
        <p>
          評価：{{ pageData['review3_rating'] }}
        </p>
        <p>
          {{ pageData['review3_text'] }}
        </p>
      </div>

      <div v-if="pageData['review4_rating']">
        <hr>
        <b-switch id="review4_display"
          class="pt-1"
          v-model="pageData['review4_display']"
          >このクチコミを公開する
        </b-switch>
        <p>
          投稿者名：{{ pageData['review4_name'] }}
        </p>
        <p>
          評価：{{ pageData['review4_rating'] }}
        </p>
        <p>
          {{ pageData['review4_text'] }}
        </p>
      </div>

      <div v-if="pageData['review5_rating']">
        <hr>
        <b-switch id="review5_display"
          class="pt-1"
          v-model="pageData['review5_display']"
          >このクチコミを公開する
        </b-switch>
        <p>
          投稿者名：{{ pageData['review5_name'] }}
        </p>
        <p>
          評価：{{ pageData['review5_rating'] }}
        </p>
        <p>
          {{ pageData['review5_text'] }}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import GoogleMapsApiLoader from 'google-maps-api-loader'
import moment from 'moment'
import Config from '../../config.js'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    }
  },
  methods: {
    async updateReviewData(placeId, swalOn = true) {
      // レビューデータを取得する
      if (this.isReviewUpdateTarget()) {
        if (this.pageData['review_date'] < moment().format('YYYY-MM-DD')) {
          const googleMap = await GoogleMapsApiLoader({
            libraries: ['places'],
            apiKey: Config.GOOGLE_MAP_APIKEY
          })

          const service = new googleMap.maps.places.PlacesService(document.createElement('div'))
          service.getDetails({
            placeId: placeId,
            fields: ['rating', 'reviews', 'user_ratings_total']
          }, (place, status) => {
            if (status == googleMap.maps.places.PlacesServiceStatus.OK) {
              this.setReviewDataToDoc(place)
              this.autosave(this.pageData)

              if (swalOn)  {
                this.$swal.fire({
                  text: 'クチコミを更新しました',
                  icon: 'success',
                })
              }
            } else {
              this.$swal.fire({
                text: 'クチコミが取得出来ませんでした',
                icon: 'warning',
              })
            }
          })
        } else {
          this.$swal.fire({
            text: 'クチコミの更新は１日１回までとなります',
            icon: 'warning',
          })
        }
      } else {
        this.setReviewDataToDoc({})
      }
    },
    setReviewDataToDoc(place) {
      this.pageData["review_display"] = true
      this.pageData["review_rating"] = place.rating ? place.rating : 0
      this.pageData["review_number"] = place.user_ratings_total ? place.user_ratings_total : 0

      for (let i = 0; i < 5; i++) {
        if (place.reviews && place.reviews[i]) {
          this.pageData["review" + (i + 1) + "_name"] = place.reviews[i].author_name ? place.reviews[i].author_name : ''
          this.pageData["review" + (i + 1) + "_rating"] = place.reviews[i].rating ? place.reviews[i].rating : 0
          this.pageData["review" + (i + 1) + "_text"] = place.reviews[i].text ? place.reviews[i].text.replace(/\r?\n/g, '') : ''
          this.pageData["review" + (i + 1) + "_display"] = place.reviews[i].rating && place.reviews[i].rating >= 3 ? true : false
        } else {
          this.pageData["review" + (i + 1) + "_name"] = ''
          this.pageData["review" + (i + 1) + "_rating"] = 0
          this.pageData["review" + (i + 1) + "_text"] = ''
          this.pageData["review" + (i + 1) + "_display"] = false
        }
      }

      this.pageData["review_date"] = (Object.keys(place).length) ? moment().format('YYYY-MM-DD') : ''
    },
    isReviewUpdateTarget() {
      return this.pageData['place_id']
    },
  },
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
</style>

<template>
  <section class="section">
    <div class="is-fluid">
      <div class="columns">
        <div class="column is-3">
          <Menu />
        </div>
        <div class="column">
          <b-tabs>
            <b-tab-item label="目標CPA">
              <b-notification aria-close-label="Close notification">
                ランディングページや、広告での費用対効果を判断する基準が目標CPAです。<br>
                以下の各項目を入力していくことで、御社サービスの目標CPAを計算できます。
              </b-notification>

              <h2 class="is-size-4">１．LTV（ライフタイムバリュー）について</h2>
              <br>
              <img src="https://ilbinc.imgix.net/images/admin_cpa01.jpg"><br>
            LTV（ライフ・タイム・バリュー）とは、１人の顧客がサービス（商品）に使用する金額の総額のことを言います。<br>
            ※今回はLTVの定義を分かりやすくするため、１人の顧客が１年間に使用する金額（売上）と定義します。<br>
              <br>
              <h3 class="is-size-5">LTV = 顧客単価 ✕ 平均年間購入回数</h3>
              <br>
              <div class="field">
                <label class="label" for="customer_unit_price"><span class="tag is-danger">必須</span>&nbsp;{{ title.customer_unit_price }}</label>
                １人の顧客がサービス（商品）に使用する金額「顧客単価」を入力してください。<br>
                例）5000<br>
                ※数値（金額）のみ入力してください。<br>
                <b-input id="customer_unit_price" v-model="doc['customer_unit_price']" @blur='onBlur'></b-input>
              </div>

              <div class="field">
                <label class="label" for="annual_average_purchases"><span class="tag is-danger">必須</span>&nbsp;{{ title.annual_average_purchases }}</label>
                １人の顧客がサービス（商品）を利用または、利用（購入）する回数「平均年間購入回数」を入力してください。
                例）12<br>
                ※数値（回数）のみ入力してください。
                <b-input id="annual_average_purchases" v-model="doc['annual_average_purchases']" @blur='onBlur'></b-input>
              </div>
              <hr>

              <h2 class="is-size-4">２．上限CPA（上限の顧客獲得単価）</h2>
              <img src="https://ilbinc.imgix.net/images/admin_cpa02.jpg"><br>

              上限CPAとは、1件のCV（コンバージョン）を獲得するために使える単価の上限です。<br>
              ※CV（コンバージョン）とは問い合わせ数、来店数、購入数などのことです。<br>
              <br>
              <h3 class="is-size-5">上限CPA ＝ LTV（年間売上高／顧客） ✕ 粗利率</h3>
              <br>
              <div class="field">
                <label class="label" for="gross_profit_ratio"><span class="tag is-danger">必須</span>&nbsp;{{ title.gross_profit_ratio }}</label>
                １人の顧客がサービス（商品）を利用または、購入した時の粗利率を入力してください。<br>
                １万円の売上があった時に6,000円の粗利益（原価が4,000円）の場合は、粗利率60%となります。<br>
                <br>
                例）60<br>
                ※数値（％）のみ入力してください。
                <b-input id="gross_profit_ratio" v-model="doc['gross_profit_ratio']" @blur='onBlur'></b-input>
              </div>

              <hr>
              <h2 class="is-size-4">３．目標CPA（目標の顧客獲得単価）</h2>
              <img src="https://ilbinc.imgix.net/images/admin_cpa03.jpg"><br>

              目標CPAとは、1件の成果（問い合わせ）を出すまでに、いくらの広告費を使えるかという基準値になります。<br>
              <br>
              <h3 class="is-size-5">目標CPA ＝ 上限CPA ✕ 広告予算比率</h3>
              <br>
              <div class="field">
                <label class="label" for="advatising_budget_ratio"><span class="tag is-danger">必須</span>&nbsp;{{ title.advertising_budget_ratio }}</label>
                広告予算比率の目安は、広告を実施する目的によって変化します。<br>
                ただし、認知ではなく利益をだすことを目的とし継続的に広告をする場合は、粗利の30%以内に留めておくことをオススメします。<br>
                <br>
                例）30<br>
                ※数値（％）のみ入力してください。
                <b-input id="advatising_budget_ratio" v-model="doc['advatising_budget_ratio']" @blur='onBlur'></b-input>
              </div>
              <hr>

              <button @click="submit( doc )" class="button is-primary">保存する</button>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import Config from '../config.js'

export default {
  components: {
    Menu,
  },
  data () {
    return {
      doc: {
        customer_unit_price: '',
        annual_average_purchases: '',
        gross_profit_ratio: '',
        advatising_budget_ratio: '',
      },
    }
  },
  methods: {
    onBlur: function() {
      this.autosave(this.doc)
    },
    autosave(p){  // 新規作成以外は自動保存
      Config.COLLECTION
        .doc(this.$store.state.lp_id)
        .set(p, { merge : true})
        .then(function(){
        })
    },
  }
}

</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
</style>

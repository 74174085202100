<template>
  <section class="section container">
    <div class="is-fluid">
      <div class="columns">
        <div class="column is-3">
          <Menu @getdoc='getPageId' :modalstatus="modal"/>
        </div>
        <div class="column table-scroll">

          <h1 class="is-size-4 has-text-weight-bold py-1">レポート（ページ）</h1>
          <div class="report-title">
            ランディングページの閲覧数（PV数）、問合せ数（CV数）、問合せ率（CV率）を確認できます。<br />
            <a href="#report">・レポート</a><br />
            <a href="#conversion">・ランディングページで問合せ数（CV数）を増やす方法</a><br />
            <p class="pl-4"><a href="#page-view">&emsp;１．閲覧数（PV数）を増やす</a></p>
            <p class="pl-4"><a href="#conversion-rate">&emsp;２．問合せ率（CV率）を高める</a></p>
          </div>
          <hr>

          <ReportBoard :page-data="doc" @modalclose="modalclose"/>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import ReportBoard from '@/components/report_board/ReportBoard';

export default {
  components: {
    ReportBoard,
    Menu
  },
    data () {
    return {
      doc: {},
      modal: true
    }
  },
  methods: {
    getPageId (value) {
      this.doc = value
    },
    modalclose() {
      console.debug("[modalclose]", this.modal)
      this.modal = false
    }
  }
}
</script>

<style>
.report-title {
  white-space: normal;
}

input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}

.table-scroll {
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
</style>

<template>
  <div>
    <b-notification aria-close-label="Close notification">
      検索用のキーワード、最寄り駅、御社の営業日、営業時間など基本的な情報を掲載できます。
    </b-notification>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="keyword_service1">
        検索用のキーワード（最大５個）
      </label>
      <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/service_keywords.png">
      <b-message type="is-danger is-light">
        検索エンジン対策に使用するキーワードです。顧客の悩みやニーズに関連するキーワードを入力してください。<br>
        ※地域名（都道府県や市区町村）や業種名（小業種）は、キーワードに入力しないでください<br>
        <br>
        【キーワードの入力ポイント】<br>
        １．ネット検索でページを表示させたいキーワード<br>
        ２．宣伝するサービスによって解決できる顧客の悩みやニーズなど<br>
        ３．文章ではなく単語（1つのキーワード）
      </b-message>

      <div v-for="key of 5" :key="key">
        <p class="pb-1" v-if="(key-1) === 0">
          例１）肥満<br>
          例２）腰痛<br>
          例３）エアコン清掃<br>
          NG例１）創業３０年（検索されない）<br>
          NG例２）福岡市 ダイエット（地域名「福岡市」は不要です）<br>
          NG例３）整体院 ぎっくり腰（業種名「整体院」は不要です）
        </p>
        <b-field
          :type="errors.has('`servicekeywords${key}`') ? 'is-danger': ''"
          :message="errors.has('`servicekeywords${key}`') ? errors.first('`servicekeywords${key}`') : ''">
          <b-input
              v-if="pageData['service_keywords']"
              :id="'service_keyword_' + key"
              name='`servicekeywords${key}`'
              maxlength="10"
              v-model="pageData['service_keywords'][key-1]"
              v-validate="{ regex: regex }"
              data-vv-as="検索キーワード"
            >
          </b-input>
        </b-field>
      </div>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="station">{{ title.station }}</label>

      <b-message type="is-danger is-light">
        検索エンジン対策に使用するキーワードです。<br>
        最寄り駅、バス停名、またはインター名を１つだけ入力してください。
      </b-message>

      <p class="pb-1">
        例１）駅名の場合：赤坂駅<br>
        例２）バス停の場合：栃木県庁前バス停<br>
        例３）インターの場合：海老名IC<br>
        NG例１）博多駅、祇園駅（最寄り駅は１つのみ入力）<br>
        NG例２）JR線博多駅 4番出口（路線、出口名は不要です）
      </p>

      <b-field
        :type="errors.has('station') ? 'is-danger': ''"
        :message="errors.has('station') ? errors.first('station') : ''"
        class="mb-0">
        <b-input
          name="station"
          v-model="pageData['station']"
          v-validate="{ regex: regex }"
          maxlength="15"
          data-vv-as="最寄り駅">
        </b-input>
      </b-field>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="access">{{ title.access }}</label>
      <p class="pb-1">
        最寄り駅（駅、バス停、ICなど）からのアクセス方法を1つ入力してください。<br>
        例１）徒歩10分<br>
        例２）車で5分
      </p>
      <b-input id="access" v-model="pageData['access']" maxlength="10"></b-input>
      <div class="pt-2">
        <div v-if="pageData['station'] && pageData['access']"  class="pb-3">
          <b-message type="is-info is-light">
            <strong>表示イメージ</strong><br>
            {{ pageData['station'] }}より{{ pageData['access'] }}
          </b-message>
        </div>
      </div>
    </div>

    <div class="field py-3">
      <label class="label has-text-weight-bold" for="opening">{{ title.opening }}</label>
      <p class="pb-1">
        定休日の場合は曜日のスイッチをオフにしてください。営業時間は、時間帯を２つまで入力できます。<br>
      </p>
      <Opening :pagedata="pageData" />
    </div>

    <div class="field py-3">
      <label class="label has-text-weight-bold" for="opening_notice">{{ title.opening_notice }}</label>
      <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/opening_notice.png">
      <p class="pb-1">
        例１）不定休<br>
        例２）祝日が月曜の場合は営業<br>
        例３）第2・3火曜日定休
      </p>
      <b-input id="opening_notice" v-model="pageData['opening_notice']"></b-input>
    </div>

    <label class="label has-text-weight-bold" for="payment">{{ title.payment }}</label>
    <p class="pb-1">
      利用可能な支払方法にチェックを入れてください。
    </p>
    <div class="field pb-3" v-for="(payment, i) in payments" :key="i">
      <input
        :id="'payment' + i"
        type="checkbox"
        :value="payment"
        v-model="checkedSortPayments"
      >
      <label :for="'payment' + i">{{payment.name}}</label>
    </div>
    <div class="field py-3">
      <label class="label has-text-weight-bold" for="parking">{{ title.parking }}</label>
      <p class="pb-1">
        例１）２台あり<br>
        例２）１台あり（近くにコインパーキングあり）
      </p>
      <b-input id="parking" v-model="pageData['parking']" maxlength="30"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="business_summary">{{ title.business_summary }}</label>
      <p class="pb-1">
      「どこの地域」で「何を提供している店舗（会社）」なのか？また「主な特長」などを入力してください。<br>
        <br>
        例）ILB整骨院は、新宿区西新宿にある、腰痛の施術に特化した整骨院です。院長は施術実績25年以上、スタッフは全員が国家資格を保有しているため、安心して施術が受けられます。
      </p>
      <b-input id="business_summary" v-model="pageData['business_summary']" maxlength="100" type="textarea"></b-input>
    </div>


    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="google_calendar_id">{{ title.google_calendar_id }}</label>
      <p class="pb-1">
        <a href="https://r.ilb.net/calendar-id-ja" target="_blank">カレンダーIDを確認する方法を見る&nbsp;<span class="icon-external-link"></span></a>
      </p>
      <b-input id="google_calendar_id" v-model="pageData['google_calendar_id']"></b-input>
    </div>


    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="contact_mail_kind">{{ title.contact_mail_kind }}</label>
      <p class="pb-1">
        希望のフォーム種類を下記２つの中から選択してください。<br>
        １．お問合せ<br>
        ２．ご予約・お問合せ<br>
        ３．フォームなし<br>
        ご予約・お問合わせを選択した場合、「営業日、営業時間」で指定した範囲内で予約が可能となります。<br>
        例）営業時間9:00 - 18:00の場合、予約フォームで選択可能な時間は9:00 - 17:00となります。
      </p>
      <b-select id="contact_mail_kind" v-model="pageData['contact_mail_kind']" placeholder="選択してください">
        <option value="inquiry" selected>お問合せ</option>
        <option value="reservation">ご予約・お問合せ</option>
        <option value="hidden">フォームなし</option>
      </b-select>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="contact_mail_notice">{{ title.contact_mail_notice }}</label>
      <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/contact_mail_notice.png">
      <p class="pb-1">
        お客様に、あらかじめ伝えておきたい内容を入力してください。<br>
        例）当日の予約やお急ぎの場合は、電話にてご連絡ください。
      </p>
      <b-input id="contact_mail_notice" v-model="pageData['contact_mail_notice']" maxlength="100" type="textarea"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="contact_mail_guide">{{ title.contact_mail_guide }}</label>
      <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/contact_mail_guide.png">
      <p class="pb-1">
        「お問合せ内容」に入力いただきたい情報を指定することができます。<br>
        例）資料請求をご希望の場合は、住所をご入力ください。
      </p>
      <b-input id="contact_mail_guide" v-model="pageData['contact_mail_guide']" maxlength="100" type="textarea"></b-input>
    </div>


    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="contact_url">{{ title.contact_url }}</label>
      <img class="pb-1" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/contact_text.png">
      <p class="pb-1">
        ※リンクを表示するためには「問合せページの表示名」の入力も必要です。
      </p>
      <b-input id="contact_url" v-model="pageData['contact_url']"></b-input>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="contact_text">{{ title.contact_text}}</label>

      <p class="pb-1">
        例１）オンライン予約はコチラ<br>
        例２）無料体験のご案内<br>
        例３）資料請求はコチラ<br>
        ※「問合せページのURL」の入力も必要です。
      </p>
      <b-input id="contact_text" v-model="pageData['contact_text']" maxlength="15"></b-input>
    </div>

    <div class="field pb-3">
      <label class="label has-text-weight-bold" for="site_policy_url">{{ title.site_policy_url }}</label>
      <p class="pb-1">
        御社のサイトポリシーが掲載されているURLを入力してください。<br>
        ※入力がない場合は、当社（株式会社ILB）で定めたサイトポリシー（ https://r.ilb.net/site-policy-ja ）が表示されます。
      </p>
      <b-input id="site_policy_url" v-model="pageData['site_policy_url']"></b-input>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="privacy_policy_url">{{ title.privacy_policy_url }}</label>
      <p class="pb-1">
        御社のプライバシーポリシーが掲載されているURLを入力してください。<br>
        ※入力がない場合は、当社（株式会社ILB）で定めたプライバシーポリシー（ https://r.ilb.net/privacy-policy-ja ）が表示されます。
      </p>
      <b-input id="privacy_policy_url" v-model="pageData['privacy_policy_url']"></b-input>
    </div>

    <div class="field">
      <label class="label has-text-weight-bold" for="disclaimer">{{ title.disclaimer }}</label>
      <p class="pb-1">
        ネット広告を利用する際などで、事例、お客様の声、クチコミに免責事項の表示が必要な場合は、表示する内容を入力してください。<br>
        例）効果には個人差があります
      </p>
      <b-input id="disclaimer" v-model="pageData['disclaimer']"></b-input>
    </div>
  </div>
</template>

<script>
import 'bulma/css/bulma.css'
import Opening from '@/components/Opening.vue'

export default {
  props: ['doc', 'title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    },
  },
  components: {
    Opening
  },
  methods: {
    getPaymentData() {
      const array = []
      if(this.payments) {
        this.payments.map(payment => {
          if(this.pageData && this.pageData.payment.indexOf(payment.name) !== -1 ) {
            array.push(payment);
          }
        })
      }
      return array
    }
  },
  data () {
    return {
      regex : /^([^/&?=<>"'#!@{};:\\|~¥*])+$/,
      payments: [
        { code:1, name:'現金' },
        { code:2, name:'銀行振込' },
        { code:3, name:'分割払い' },
        { code:4, name:'VISA' },
        { code:5, name:'Mastercard' },
        { code:6, name:'JCB' },
        { code:7, name:'AMEX' },
        { code:8, name:'Diners' },
        { code:9, name:'DISCOVER' },
        { code:10, name:'銀聯' },
        { code:11, name:'LINE Pay' },
        { code:12, name:'PayPay' },
        { code:13, name:'楽天ペイ' },
        { code:14, name:'d払い' },
        { code:15, name:'メルペイ' },
        { code:16, name:'au PAY' },
        { code:17, name:'Alipay' },
        { code:18, name:'WeChat Pay' }
      ],
      checkedSortPayments : [],
    }
  },
  watch: {
    // ソート処理用の動的変数
    checkedSortPayments : function(oldVal, newVal) {
      if (oldVal.length !== newVal.length) {
        // codeでソート処理
        this.checkedSortPayments.sort((a, b) => a.code - b.code)
        // 配列作成しなおしてpage_dateに入れる。
        this.pageData.payment = this.checkedSortPayments.map((checkedSortPayment) => checkedSortPayment.name)
      }
    },
    doc : function() {
      this.checkedSortPayments = this.getPaymentData();
    }
  },
  mounted () {
    this.checkedSortPayments = this.getPaymentData();
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.is-clearfix::after {
  display: contents !important;
}

</style>

<template>
  <section class="section container">
    <div class="columns">
      <div class="column is-3">
        <Menu @getdoc='getPageId' />
      </div>
      <div class="column">
        <div v-if='doc'>
          <div class="pb-3">
            <h1 class="is-size-4 has-text-weight-bold py-1">掲載情報の入力・修正</h1>
            <p class="">
              下記より入力したい項目を選択してください（必須項目、基本情報、オファーなど）
            </p>
            <p class="has-text-weight-bold has-text-danger pb-1">
              ※入力した情報はページ最下部の「変更内容を保存する」ボタンを押すと保存されます
            </p>
            <p v-if="!disapproved">
              広告を実施している場合、広告内容も変更されます。
            </p>
            <b-notification v-if="doc['category_group'] == '医療機関'" aria-close-label="Close notification">
              医療機関の場合、掲載する内容は「医療広告ガイドライン」による規制の対象となります。<br>
              <a href="https://r.ilb.net/ip-adspolicy-ja" target="_blank">広告掲載の審査基準を確認する&nbsp;<span class="icon-external-link"></span></a>
            </b-notification>
          </div>

          <b-tabs v-model='activeTab' v-if='display' class='is-multiline' type='is-toggle'>

            <b-tab-item label="必須項目">
              <RequiredTab :doc="doc" :charges_doc="charges_doc" :title="title" @imgUpdate="imgUpdate" ref="businessimage"/>
            </b-tab-item>
            <b-tab-item label="基本情報">
              <BasicInfoTab :doc="doc" :title="title"/>
            </b-tab-item>
            <b-tab-item label="オファー">
              <OfferTab :doc="doc" :title="title" @imgUpdate="imgUpdate" ref="offerimage"/>
            </b-tab-item>
            <b-tab-item label="LINE">
              <LineTab :doc="doc" :title="title" @imgUpdate="imgUpdate" ref="lineimage"/>
            </b-tab-item>
            <b-tab-item label="事例">
              <CaseTab :doc="doc" :title="title" @imgUpdate="imgUpdate" ref="caseimage"/>
            </b-tab-item>
            <b-tab-item label="特長">
              <FeatureTab :doc="doc" :title="title" @imgUpdate="imgUpdate" ref="featureimage"/>
            </b-tab-item>
            <b-tab-item label="スタッフ">
              <IntroStaffTab :doc="doc" :title="title" @imgUpdate="imgUpdate" ref="staffimage"/>
            </b-tab-item>
            <b-tab-item label="提供サービス">
              <ServiceTab :doc="doc" :title="title" @imgUpdate="imgUpdate" ref="serviceimage"/>
            </b-tab-item>
            <b-tab-item v-if="doc['category_group'] !== '医療機関'" label="お客様の声">
              <VoiceOfCustomerTab :doc="doc" :title="title" @imgUpdate="imgUpdate" ref="vocimage"/>
            </b-tab-item>
            <b-tab-item label="よくある質問">
              <FrequentlyAskedQuestionsTab :doc="doc" :title="title"/>
            </b-tab-item>
            <b-tab-item v-if="doc['category_group'] !== '医療機関'" label="クチコミ">
              <WordOfMouthTab :doc="doc" :title="title"/>
            </b-tab-item>
          </b-tabs>
          <div class="bottom-menu pt-1 pb-2">
            <div class="mt-1">
              <button :disabled="disabled" class="button is-info" @click="save_submit()">
                <span>
                  変更内容を保存する
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :can-cancel="true"></b-loading>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import BasicInfoTab from '@/components/main/BasicInfoTab.vue'
import FeatureTab from '@/components/main/FeatureTab.vue'
import FrequentlyAskedQuestionsTab from '@/components/main/FrequentlyAskedQuestionsTab.vue'
import IntroStaffTab from '@/components/main/IntroStaffTab.vue'
import LineTab from '@/components/main/LineTab.vue'
import RequiredTab from '@/components/main/RequiredTab.vue'
import ServiceTab from '@/components/main/ServiceTab.vue'
import WordOfMouthTab from '@/components/main/WordOfMouthTab.vue'
import CaseTab from '@/components/main/CaseTab.vue'
import OfferTab from '@/components/main/OfferTab.vue'
import VoiceOfCustomerTab from '@/components/main/VoiceOfCustomerTab.vue'
import moment from 'moment'
import Config from '../config.js'
import Compressor from 'compressorjs';

export default {
  mixins: [Menu],
  components: {
    Menu,
    BasicInfoTab,
    CaseTab,
    FeatureTab,
    FrequentlyAskedQuestionsTab,
    IntroStaffTab,
    OfferTab,
    LineTab,
    VoiceOfCustomerTab,
    RequiredTab,
    ServiceTab,
    WordOfMouthTab,
  },
  computed : {
    // Google検索広告の契約中・未契約
    disapproved () {
      if (this.ads_doc && this.ads_doc.disapproved) {
          return true;
      }
      return false
    },
  },
  methods: {
    /**
     * Menu（子コンポーネント）から選択したdocを受け取る
     */
    getPageId (value) {
      this.doc = value
      // ** page選択したらアップロード前のimageDataのリセット
      this.imageDate = {}
    },
    /**
     * 画像アップロード処理（保存ボタン押した時に発火）
     * 各子コンポーネント上での画像アップロードデータをimageDataに入れる
     * 配列上に保存しておいて保存ボタンで一気にアップロード＋削除
     */
    imgUpdate (imageFileData) {
      // 配列の初期化
      if (this.imageDate[imageFileData['imageTitle']] === undefined) {
        this.imageDate[imageFileData['imageTitle']] = []
      }

      // business_image, staff_image, line_image, offer_imageはarrayNumは0
      this.imageDate[imageFileData['imageTitle']][imageFileData['arrayNum'] || 0] = imageFileData
    },
    /**
     * ページ保存ボタン送信
     */
    async save_submit () {
      // ドキュメントIDとpage_idが一致しない場合は強制ログアウト
      if(this.selectedId !== this.doc.page_id) {
        await this.$swal('error', 'ページが変更されました。再度ログインしてください。', 'error')
        await this.$store.dispatch('logout')
        await this.$router.push('/login').catch(err => { console.log(err)})
        return
      }
      
      // ボタンをアクティブ
      this.disabled = true
      // loading
      this.isLoading = true
      // 画像関連の変更がある場合には保存もしくは削除
      if(Object.keys(this.imageDate).length !== 0){
        // 画像処理
        const result = await this.uploadAndRemove()
        // imageDataのリセット
        this.imageDate = {}
        // 保存
        // console.log('【画像変更あり保存】', result)
        await this.save(result)
        await this.listenData(this.selectedId)
        this.disabled = false
        // loading
        this.isLoading = false
        return
      }

      // console.log('【画像変更なし保存】', this.doc)
      await this.save()
      await this.listenData(this.selectedId)
      this.disabled = false
      // loading
      this.isLoading = false
      return
    },
    /**
     * 画像アップロードor削除
     */
    async uploadAndRemove() {
      // アップロードおよび削除予定の画像他の情報
      const imageFileData = this.imageDate

      let doc = this.doc

      // アップロード＋page_dataへのURL保存
      const uploadAndRemove = (data) => {
        // Promise を返却
        return new Promise((resolve) => {
          Object.keys(data).map(imageTitle => {
            Object.keys(data[imageTitle]).map(arrayNum => {
              const image = data[imageTitle][arrayNum]
              // 削除の場合には飛ばす
              if(!image.isDeleted) {
                doc = this.imgUpload(imageTitle, arrayNum, image)
              } else {
                // 削除対象のURLがあれば
                if (image.src) {
                  doc = this.imgRemove(imageTitle, arrayNum, image)
                }
              }
            })
          })
          resolve(doc)
        })
      }
      return await uploadAndRemove(imageFileData) // Promise が返却される
    },
    /**
     * 画像アップロード
     */
    async imgUpload(imageTitle, arrayNum, image) {
      // firebase storage
      const storage = Config.FIREBASE_STORAGE;

      // 画像のアップロード先の準備（ファイル名は新規作成）
      const mountRef = await storage
        .ref()
        .child(
          Config.STORAGE_DIR +
            "/" +
            this.selectedId +
            "/" +
            imageTitle +
            "_" +
            (Math.random().toString(36).slice(-8)) +
            ".jpg"
        );

      // 圧縮+jpg化+アップロード+page_dataへのURL保存
      return await this.compress(image, mountRef, arrayNum, imageTitle)
    },
    /**
     * 画像アップロード時の圧縮・保存
     */
    async compress(image, mountRef, arrayNum, imageTitle) {
      const deleteFileUrl = image.src

      const promisfiedCompressor = ( file, q=0.92, maxWidth=1000, maxHeight=800, mimeType='image/jpeg') => {
        // Promise を返却
        return new Promise((resolve, reject) => {

          new Compressor(file, {
            quality: q,
            maxWidth:maxWidth,
            maxHeight: maxHeight,
            mimeType: mimeType,
            success(blob) {
              resolve(blob); // Promise を 成功終了
            },
            error(err) {
              reject(err); // Promise を 異常終了
            }
          }); // Compressor.js
        });
      }

      const file = await promisfiedCompressor(image.file) // Promise が返却される

      try {
        // 画像アップロード保存
        const snapshot = await mountRef.put(file)
        // 保存後のURL取得
        const url = await snapshot.ref.getDownloadURL()

        let docFieldData = this.doc[image.docFieldTitle]

        const db = Config.COLLECTION.doc(this.selectedId)

        // business_image|staff_image|line_image|offer_imageの場合は別処理
        switch (imageTitle) {
          case 'business_image':
          case 'line_image':
          case 'staff_image':
          case 'offer_image':
            // this.docの内容上書き
            this.doc[imageTitle] = url;
            await db.update({ [imageTitle]: url })
          break;

          case 'case_image1':
            docFieldData[arrayNum]['image1'] = url
            this.doc[image.docFieldTitle] = docFieldData;
            await db.update({ [image.docFieldTitle]: docFieldData })
            break;

          case 'case_image2':
            docFieldData[arrayNum]['image2'] = url
            this.doc[image.docFieldTitle] = docFieldData;
            await db.update({ [image.docFieldTitle]: docFieldData })
            break;

          default:
            // feature|service|voc
            docFieldData[arrayNum]['image'] = url
            this.doc[image.docFieldTitle] = docFieldData;
            await db.update({ [image.docFieldTitle]: docFieldData })
            break;
          }
          // 既存ファイル削除
          if(deleteFileUrl) {
            await this.deleteStorageFile(deleteFileUrl);
          }
        } catch (error) {
          console.log(error)
          this.$swal("画像アップロードエラー", "エラー内容：" + error, "error");
        }

      // 画面上のcanvas削除 refsを使って子コンポーネントのreset関数呼び出し
      this.$refs[imageTitle.replace(/_/g, "").replace(/[0-9]/g, "")].reset();
      return this.doc
    },
    /**
     * ページデータのまとめて保存
     */
    async save(p=this.doc) {
      const page_id = this.selectedId
      // 更新時間
      p.date = moment(moment().unix(),'X').format()

      // 更新不要のデータ項目を除く
      Config.EXCEPT_KEYS.forEach( key => delete p[key])

      // バッチ保存に変更
      try {
        const batch = await Config.FIREBASE_STORE.batch();
        await batch.set(Config.COLLECTION.doc(page_id), p, {merge : true })
        await batch.commit();
        console.log('保存前doc', p)
      } catch(e) {
        await this.$swal('error', '保存エラー：' + e, 'error')
        return
      }

      // ３つのswal分岐
       try {
        // if(this.charges_doc && ((this.charges_doc.gs && this.charges_doc.gs.payment_date)
        //   || (this.charges_doc.gd && this.charges_doc.gd.payment_date))
        //   ) {
        //   // 分岐条件①：広告設定の必須項目：C列「1」の項目が未入力の場合の完了画面
        //   if(this.requiredCheck(p).length > 0) {
        //     // 必須項目の入力を促しページ移動＋メール送信（広告ごと）
        //     await this.swalNotAdRequiredField(this.requiredCheck(p))
        //   } else {
        //     // 分岐条件②：広告の設定項目が更新された際の完了画面：広告の設定項目：D列「1」の項目
        //     await this.swalChangeAdPublish()

        //     // ads_data > gs,gd > ads_change_date：当日の日付を更新, gs,gd > ad_group_id：0にする
        //     // 広告が更新されたので広告グループのリセット
        //     try {
        //       const batch = await Config.FIREBASE_STORE.batch();
        //       await batch.set(Config.ADS_COLLECTION.doc(this.doc['page_id']), {
        //         ads_change_date : moment().format("YYYY/MM/DD"),
        //         gd : {
        //           ad_group_id : 0
        //         },
        //         gs : {
        //           ad_group_id : 0
        //         }
        //       },
        //       {merge : true })
        //       await batch.commit();
        //     } catch(e) {
        //       this.$swal('error', '保存エラー：' + e, 'error')
        //       return
        //     }
        //   }
        // } else {
          // 分岐条件③ 有料プランも広告も行っていない
          console.log('パターン3')
          await this.swalOnlyPage()
        // }
      } catch (error) {
        this.$swal('error', '保存エラー：' + error, 'error')
      }
      // ボタンをアクティブ
      this.disabled = false
      await this.listenData(page_id)
    },
    // /**
    //  * 広告に必須項目のチェック
    // */
    // requiredCheck (d) {
    //   let notRequiredData = [];
    //   if(!d["business_name"]) { notRequiredData.push('事業所名'); }
    //   if(!d["zip_code"]) { notRequiredData.push('郵便番号'); }
    //   if(!d["region"]) { notRequiredData.push('都道府県'); }
    //   if(!d["city"]) { notRequiredData.push('市区町村'); }
    //   if(!d["station"]) { notRequiredData.push('最寄り駅（駅、バス停、ICなど）'); }
    //   if(!d["access"]) { notRequiredData.push('アクセス'); }
    //   if(!d["category_group"]) { notRequiredData.push('大業種'); }
    //   if(!d["category"]) { notRequiredData.push('小業種'); }
    //   if(!d["phone"]) { notRequiredData.push('電話番号（ページ掲載用）'); }
    //   if(!d["email"]) { notRequiredData.push('メールアドレス'); }
    //   if(!d["catchphrase"]) { notRequiredData.push('キャッチフレーズ'); }
    //   if(!d["service_keywords"][0]) { notRequiredData.push('検索用のキーワード1'); }
    //   if(!d["service_keywords"][1]) { notRequiredData.push('検索用のキーワード2'); }
    //   if(!d["service_keywords"][2]) { notRequiredData.push('検索用のキーワード3'); }
    //   if(!d["service_keywords"][3]) { notRequiredData.push('検索用のキーワード4'); }
    //   if(!d["service_keywords"][4]) { notRequiredData.push('検索用のキーワード5'); }
    //   if(!d["feature"][0]["title"]) { notRequiredData.push('特長1のタイトル'); }
    //   if(!d["feature"][1]["title"]) { notRequiredData.push('特長2のタイトル'); }
    //   if(!d["feature"][2]["title"]) { notRequiredData.push('特長3のタイトル'); }
    //   if(!d["feature"][3]["title"]) { notRequiredData.push('特長4のタイトル'); }
    //   if(!d["feature"][4]["title"]) { notRequiredData.push('特長5のタイトル'); }
    //   if(!d["business_image"]) { notRequiredData.push('御社の画像'); }
    //   if(!d["feature"][0]["image"]) { notRequiredData.push('特長1の画像'); }
    //   if(!d["feature"][1]["image"]) { notRequiredData.push('特長2の画像'); }
    //   if(!d["feature"][2]["image"]) { notRequiredData.push('特長3の画像'); }
    //   if(!d["feature"][3]["image"]) { notRequiredData.push('特長4の画像'); }
    //   if(!d["feature"][4]["image"]) { notRequiredData.push('特長5の画像'); }
    //   if(this.strCount(d['city'] + 'の' + d['category']) > 30) {
    //     if(!d["ads_title"] || this.strCount(d["ads_title"]) > 30) {
    //       notRequiredData.push('市区町村の小業種')
    //     }
    //   }
    //   return notRequiredData
    // },
    // // ads_titleのカウント用
    // strCount (str) {
    //   let len = 0;
    //   for (let i = 0; i < str.length; i++) {
    //     (str[i].match(/[ -~]/)) ? len += 1 : len += 2;
    //     }
    //   return len;
    // },
    // /**
    //  * 1．広告設定の必須項目：C列「1」の項目が未入力の場合の完了画面
    //  */
    // async swalNotAdRequiredField (array) {
    //   const notRequiredData = array.join('<br>')
    //   const result = await this.$swal.fire(
    //       {
    //       title: "",
    //       icon: 'success',
    //       html:'変更を保存しました' + '<br><br>'
    //         + 'ページURL（'+ (this.pageUrlCheck ? '公開中' : '非公開')+ '）<br>'
    //         + '<a href="https://jp.ilb.io/' + this.doc['page_id'] + '" target="_blank">https://jp.ilb.io/' + this.doc['page_id'] + '</a>'
    //         + '<br><br>'
    //         + '<span style="color:red;">未入力の項目があるため広告が設定できません。未入力の項目を全て入力してください。<span><br>'
    //         + '<br><br>'
    //         + '<span>＜未入力の項目＞</span>'
    //         + '<br>'
    //         + notRequiredData,
    //       confirmButtonText : '広告設定に必要な項目を入力する'
    //     });

    //   if (result.isConfirmed) {
    //     // メールを送る J列
    //     // sendmail_targetの追加（gs）
    //     if(this.charges_doc.gs && this.charges_doc.gs.payment_date) {
    //       let data = {
    //         template_id : 'NOT_AD_REQUIRED_FILED_NOTIFICATION',
    //         empty_ads_required_items: array,
    //         admin_url: Config.MANAGE_URL,
    //         page_id: this.$store.state.page_id,
    //         email_auth : this.doc.email || this.$store.state.user.email,
    //         url :Config.DESTINATION_URL + '/' + this.$store.state.page_id + '/',
    //         service_name : Config.SERVICE_NAMES['gs'],
    //         budget : this.ads_doc.gs.budget.toLocaleString(),
    //         business_name: this.doc.business_name,
    //         branch_name: this.doc.branch_name || '',
    //         status: 'sending',
    //         date: moment().format("YYYY-MM-DD HH:MM:SS")
    //       }
    //       await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    //     }

    //     // sendmail_targetの追加（gd）
    //     if(this.charges_doc.gd && this.charges_doc.gd.payment_date) {
    //       let data = {
    //         template_id : 'NOT_AD_REQUIRED_FILED_NOTIFICATION',
    //         empty_ads_required_items: array,
    //         admin_url: Config.MANAGE_URL,
    //         page_id: this.$store.state.page_id,
    //         email_auth : this.doc.email || this.$store.state.user.email,
    //         url :Config.DESTINATION_URL + '/' + this.$store.state.page_id + '/',
    //         service_name : Config.SERVICE_NAMES['gd'],
    //         budget : this.ads_doc.gd.budget.toLocaleString(),
    //         business_name: this.doc.business_name,
    //         branch_name: this.doc.branch_name || '',
    //         status: 'sending',
    //         date: moment().format("YYYY-MM-DD HH:MM:SS")
    //       }
    //       await Config.SENDMAIL_TARGET_COLLECTION.add(data);
    //     }
    //     // 広告の項目確認ページへ
    //     this.$router.push('ads_data').catch(err => { console.log(err)})
    //     return
    //   }
    //   return
    // },

    // /**
    //  * ２．広告の設定項目が更新された際の完了画面
    //  */
    // async swalChangeAdPublish () {
    //   await this.$swal.fire(
    //     {
    //       title: "",
    //       icon: 'success',
    //       html:'変更を保存しました' + '<br><br>'
    //         + 'ページURL（'+ (this.pageUrlCheck ? '公開中' : '非公開')+ '）<br>'
    //         + '<a href="https://jp.ilb.io/' + this.doc['page_id'] + '" target="_blank">https://jp.ilb.io/' + this.doc['page_id'] + '</a>'
    //         + '<br><br>'
    //         + '<span>保存した内容をもとに、広告内容も変更されます。</span>'
    //         + '<br><br>'
    //         + '<span>＜変更される広告の種類＞</span>'
    //         + '<br>'
    //         + (this.charges_doc.gs && this.charges_doc.gs.payment_date ? 'Google広告（検索）<br>' : '')
    //         + (this.charges_doc.gd && this.charges_doc.gd.payment_date ? 'Google広告（ディスプレイ）<br>' : '')
    //         + '<br><br>'
    //         + '<span>Googleの審査により広告が一時的に非表示となりますが、審査が完了次第、再度表示されます。</span>',
    //       confirmButtonText : '管理画面に戻る'
    //     }
    //   )
    // },
    /**
     * 3.有料プランも広告も行っていない
     */
     async swalOnlyPage () {
      await this.$swal.fire({
        title: '',
        html:'変更を保存しました' + '<br><br>'
          + 'ページURL（' + (this.pageUrlCheck ? '公開中' : '非公開') + '）<br>'
          + '<a href="https://jp.ilb.io/' + this.doc['page_id'] + '" target="_blank">https://jp.ilb.io/' + this.doc['page_id'] + '</a>',
        icon: 'success',
        confirmButtonText: '管理画面に戻る',
      })
      return
    },
    /**
     * 画像削除
     * imageTitleの種類は以下の８種類
     * service_image
     * feature_image
     * staff_image
     * line_image
     * business_image
     * offer_image
     * case_image
     * voc_image
     */
    async imgRemove(imageTitle, arrayNum, image) {
      const db = Config.COLLECTION.doc(this.selectedId)
      let doc = this.doc

      let docFieldData = this.doc[image.docFieldTitle]

      switch (imageTitle) {
        case 'business_image':
        case 'line_image':
        case 'staff_image':
        case 'offer_image':
          doc[imageTitle] = ''
          await db.update({ [imageTitle]: '' })
          break;

        case 'case_image1':
          docFieldData[arrayNum]['image1'] = ""
          doc['case'][arrayNum]['image1'] = ''
          await db.update({ case : docFieldData })
          break;

        case 'case_image2':
          docFieldData[arrayNum]['image2'] = ""
          doc['case'][arrayNum]['image2'] = ''
          await db.update({ case : docFieldData })
          break;

        default:
          // feature|service|voc|
          docFieldData[arrayNum]['image'] = ""
          doc[image.docFieldTitle][arrayNum]['image'] = ''
          await db.update({ [image.docFieldTitle] : docFieldData })
          break;
      }

      // storageからファイル削除
      await this.deleteStorageFile (image.src);
      return await this.doc
    },
    /**
     * firebase storageから実ファイルの削除
     */
    async deleteStorageFile (image_src_url) {
      // firebase storageのURLからファイル名を取得
      const deleteFile = image_src_url.split(/\/|\?/);
      // firebase storage
      const storage = Config.FIREBASE_STORAGE;
      // 削除用ファイルのオブジェクト取得
      const desertRef = storage
        .ref()
        .child(decodeURIComponent(deleteFile[7]));

      // 画像ファイル削除処理
      try {
        await desertRef.delete();
      } catch (error) {
        this.$swal("画像削除エラー", "エラー内容：" + error, "error");
      }
    }
  },
  data () {
    return {
      imageDate: {},
      activeTab: 0,
      doc: this.doc,
      ads_doc: this.ads_doc,
      disabled: false,
      display: true,
      isLoading: false,
      isFullPage: true,
      options: [
        { text: '--', value: '' },
        { text: '00:00', value: '00:00' },
        { text: '00:30', value: '00:30' },
        { text: '01:00', value: '01:00' },
        { text: '01:30', value: '01:30' },
        { text: '02:00', value: '02:00' },
        { text: '02:30', value: '02:30' },
        { text: '03:00', value: '03:00' },
        { text: '03:30', value: '03:30' },
        { text: '04:00', value: '04:00' },
        { text: '04:30', value: '04:30' },
        { text: '05:00', value: '05:00' },
        { text: '05:30', value: '05:30' },
        { text: '06:00', value: '06:00' },
        { text: '06:30', value: '06:30' },
        { text: '07:00', value: '07:00' },
        { text: '07:30', value: '07:30' },
        { text: '08:00', value: '08:00' },
        { text: '08:30', value: '08:30' },
        { text: '09:00', value: '09:00' },
        { text: '09:30', value: '09:30' },
        { text: '10:00', value: '10:00' },
        { text: '10:30', value: '10:30' },
        { text: '11:00', value: '11:00' },
        { text: '11:30', value: '11:30' },
        { text: '12:00', value: '12:00' },
        { text: '12:30', value: '12:30' },
        { text: '13:00', value: '13:00' },
        { text: '13:30', value: '13:30' },
        { text: '14:00', value: '14:00' },
        { text: '14:30', value: '14:30' },
        { text: '15:00', value: '15:00' },
        { text: '15:30', value: '15:30' },
        { text: '16:00', value: '16:00' },
        { text: '16:30', value: '16:30' },
        { text: '17:00', value: '17:00' },
        { text: '17:30', value: '17:30' },
        { text: '18:00', value: '18:00' },
        { text: '18:30', value: '18:30' },
        { text: '19:00', value: '19:00' },
        { text: '19:30', value: '19:30' },
        { text: '20:00', value: '20:00' },
        { text: '20:30', value: '20:30' },
        { text: '21:00', value: '21:00' },
        { text: '21:30', value: '21:30' },
        { text: '22:00', value: '22:00' },
        { text: '22:30', value: '22:30' },
        { text: '23:00', value: '23:00' },
        { text: '23:30', value: '23:30' }
      ]
    }
  },
  async beforeMount() {
    // queryにactive_tabが設定されてた場合、初期表示のタブとして設定
    // 設定後queryは削除する
    if (this.$route.query.active_tab) {
      this.activeTab = Number(this.$route.query.active_tab)
      let query = Object.assign({}, this.$route.query)
      delete query['active_tab']
      this.$router.push({query: query})
      window.scrollTo(0, 0)
    }
  }
}

</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.bottom-menu {
  position: fixed;
  bottom: 20px;
  width: 100%;
  left: 0;
  text-align: center;
  z-index: 4;
  background: rgba(30,30,30,.9);
}
img {
  border: solid 1px #CCCCCC; 
}
</style>

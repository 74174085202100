<template>
<section class="section container">

  <div class="columns">
    <div class="column is-3">
      <Menu @getdoc='getPageId' />
    </div>
    <div class="column">

        <h1 class="is-size-4 has-text-weight-bold py-1">紹介プログラム</h1>
        <div class="">
          <p class="pb-1">
            ランディングページ作成サービスでは、紹介プログラムを導入しています。
          </p>
          <p>
            紹介プログラムの詳細、紹介方法、報酬についてご確認いただけます。<br>
            下記より表示したい項目を選択してください。
          </p>
        </div>
        <hr>
        <div>

          <b-tabs>

            <b-tab-item label="プログラムの詳細">

              <b-notification aria-close-label="Close notification">
                御社のご紹介にてランディングページを新規登録された方が、<br>
                有料プランを利用された場合、御社に紹介料（利用料の30％）をお支払いするプログラムです。<br>
                １回限りの報酬ではなく、有料プランの利用が続く限り、継続的に報酬をお受け取りいただけます。<br>
                <br>
                紹介プログラムの詳細については、利用規約をご確認ください。<br>
                ※自分で自分を紹介することは規約違反となりますのでご注意ください<br>
                <a href="https://r.ilb.net/tos-ja" target="_blank">利用規約を見る&nbsp;<span class="icon-external-link"></span></a>
              </b-notification>


              <h2 class="title is-4 is-size-5-mobile" id="">報酬の受取について</h2>

              <div class="field">
                <b-radio v-model="referral_doc['declined']"
                    name="報酬の受取"
                    native-value="false"
                    @input="onBlur"
                >
                    報酬を受取る
                </b-radio>
                <b-radio v-model="referral_doc['declined']"
                    name="報酬の受取"
                    native-value="true"
                    @input="onBlur"
                >
                    報酬を受取らない
                </b-radio>
                <p>
                  「報酬を受取らない」を選択すると、紹介数や報酬額をお知らせするメールも配信停止されます
                </p>
              </div>

              <hr>
              
              <h2 class="title is-4 is-size-5-mobile" id="">１．紹介方法</h2>
              <p class="pb-1">
                １）紹介URLから新規登録していただく<br>
              </p>

              <b-message type="is-info is-light">
                御社のご紹介URL<br>
                <a :href="'https://admin-jp.ilb.io/signup?code=' + uid" target="_blank">
                  https://admin-jp.ilb.io/signup?code={{ uid }}
                </a>
              </b-message>

              <p class="pb-3">
                <span class="has-text-weight-bold">御社のご紹介URLの活用例</span><br>
                ①知り合いの経営者に送る<br>
                ②ブログやSNSで紹介する
              </p>

              <p>
                下記の文章をコピーして、ご活用ください。
              </p>

              <b-message type="is-info is-light">
                新規顧客を増やすことだけに特化した<br>
                ランディングページが無料で作成できます。<br>
                <a :href="'https://admin-jp.ilb.io/signup?code=' + uid" target="_blank">
                  https://admin-jp.ilb.io/signup?code={{ uid }}
                </a>
              </b-message>

              <br>

              <p class="pb-1">
                ２）紹介チラシのQRコードから新規登録していただく<br>
                下記より御社専用の紹介チラシがダウンロードできます。印刷してお使いください。
              </p>

              <a :href="'http://app.ilb.io/flyer/lp/referral.html?id=' + uid" target="_blank" class="button is-success">紹介チラシをダウンロードする</a><br>

              <p class="pt-3">
                <span class="has-text-weight-bold">チラシの使用例</span><br>
                ①知り合いの経営者に渡す<br>
                ②取引先、業者に渡す<br>
                ③店舗や事業所に置いておく
              </p>

              <hr>

              <h2 class="title is-4 is-size-5-mobile" id="">２．報酬の計算方法</h2>
              <p class="pb-3">
                報酬の計算は、毎月末日に実施します。<br>
              </p>
              <p class="pb-1">
                有料プラン件数が30件の場合<br>
                30件&nbsp;☓&nbsp;990円
              </p>
              <p class="has-text-weight-bold">
                酬額の合計金額：29,700円<br>
              </p>

            </b-tab-item>

            <b-tab-item label="振込先口座">

              <b-notification aria-close-label="Close notification">
                １．報酬のお支払について<br>
                報酬は月末締め、翌月25日に前月末時点で入力されている「振込先口座」にお振込みします。<br>
                ※25日が土日祝日等の金融機関休業日にあたる場合は、翌営業日での振込となります。<br>
                <br>
                ２．振込額について<br>
                振込額は、月額報酬額（税込み）から振込手数料を引いた金額となります。<br>
                振込手数料の詳細は下記よりご確認ください。<br>
                <a href="https://r.ilb.net/referral-transfer-fee-ja" target="_blank">https://r.ilb.net/referral-transfer-fee-ja</a><br>
                <br>
                ３．振込先口座の入力について<br>
                <span class="has-text-danger">
                報酬計算は毎月末日に実施します。そのため月末時点で必須項目が1つでも入力されていない場合は、報酬は消滅となりますのでご注意ください。<br>
                ※入力後は必ず「保存する」ボタンをクリックしてください。
                </span>
              </b-notification>


              <h2 class="title is-4 is-size-5-mobile" id="">口座情報</h2>
              <p class="pb-1">
                報酬の振込先口座を入力してください。<br>
                ※自身に帰属する金融機関口座を指定してください。<br>
              </p>

              <b-field
                :type="errors.has('bank_code') ? 'is-danger': ''"
                :message="errors.has('bank_code') ? bank_code_not_half_number_message : ''">
                <template slot="label">
                  <span class="tag is-danger">必須</span>&nbsp;銀行番号（半角数字4ケタ）
                </template>
                <div class="control">
                  <b-input
                    name="bank_code"
                    v-model="referral_doc['bank_code']"
                    v-validate="'required|numeric|max:4'"
                    data-vv-as="銀行番号"
                    id="bank_code"
                    maxlength="4"
                  >
                  </b-input>
                </div>
              </b-field>

              <b-field
                :type="errors.has('branch_code') ? 'is-danger': ''"
                :message="errors.has('branch_code') ? branch_code_not_half_number_message : ''">
                <template slot="label">
                  <span class="tag is-danger">必須</span>&nbsp;支店番号（半角数字3ケタ）
                </template>
                <div class="control">
                  <b-input
                    name="branch_code"
                    v-model="referral_doc['branch_code']"
                    v-validate="'required|numeric|max:3'"
                    data-vv-as="支店番号"
                    id="branch_code"
                    maxlength="3"
                  >
                  </b-input>
                </div>
              </b-field>

              <div class="field">
                <label class="label has-text-weight-bold" for="account_type"><span class="tag is-danger">必須</span>&nbsp;預金種目</label>

                <b-radio v-model="referral_doc['account_type']"
                    name="預金種目"
                    native-value="1"
                    >
                    普通
                </b-radio>
                <b-radio v-model="referral_doc['account_type']"
                    name="預金種目"
                    native-value="2"
                    >
                    当座
                </b-radio>
                <b-radio v-model="referral_doc['account_type']"
                    name="預金種目"
                    native-value="4"
                    >
                    貯蓄
                </b-radio>
              </div>

              <b-field
                :type="errors.has('account_number') ? 'is-danger': ''"
                :message="errors.has('account_number') ? account_number_not_half_number_message : ''">
                <template slot="label">
                  <span class="tag is-danger">必須</span>&nbsp;口座番号（半角数字7ケタ）<br>
                  <span class="memo">※口座番号が7桁に満たない場合は、先頭に「0」を入力して、全部で7桁となるように入力してください。</span>
                </template>
                <div class="control">
                  <b-input
                    name="account_number"
                    v-model="referral_doc['account_number']"
                    v-validate="'required|numeric|max:7'"
                    data-vv-as="口座番号"
                    id="account_number"
                    maxlength="7"
                  >
                  </b-input>
                </div>
              </b-field>

              <b-field
                :type="errors.has('account_name') ? 'is-danger': ''"
                :message="errors.has('account_name') ? errors.first('account_name') : ''">
                <template slot="label">
                  <span class="tag is-danger">必須</span>&nbsp;口座名義（全角カナ）<br>
                  <span class="memo">
                  ※通帳やオンラインバンキングに記載されている通り、正確な口座名を入力してください。<br>
                  例）<br>
                  ヤマダ&nbsp;&nbsp;ハナコ<br>
                  カ）アイエルビー<br>
                  シヤ）アイエスビー</span>
                </template>
                <div class="control">
                  <b-input
                    name="account_name"
                    v-model="referral_doc['account_name']"
                    v-validate="{regex:regex}"
                    data-vv-as="口座名義"
                    id="account_name"
                    maxlength="30"
                  >
                  </b-input>
                </div>
              </b-field>

              入力後は必ず「保存する」ボタンをクリックしてください。<br>

              <span>
                  <div v-if="displayButtons">
                    <div class="control">
                      <button
                        class="button is-success"
                        id="saveForm"
                        :disabled="errors.any() || !isFormCompleted"
                        @click="submit_referral( referral_doc )"
                      >
                        保存する
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <loading-for-submit />
                  </div>
              </span>

            </b-tab-item>

          </b-tabs>
        </div>
      </div>
    </div>

</section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'
import LoadingForSubmit from '@/components/LoadingForSubmit'
import Config from '../config.js'

export default {
  components: {
    LoadingForSubmit,
    Menu,
  },
  data () {
    return {
      uid : this.$store.state.user.uid,
      referral_doc: [],
      draft_referral:{
        account_number: '',
        account_name: '',
        account_type: '1',
        bank_code: '',
        branch_code: '',
        declined: 'false',
        deleted: 'false',
        referral_code: '',
      },
      regex: /^([ァ-ン]|ー|（|）|\u3000)+$/,
      displayButtons: true,
      bank_code_not_half_number_message: '銀行番号は半角数字のみ使用できます',
      branch_code_not_half_number_message: '支店番号は半角数字のみ使用できます',
      account_number_not_half_number_message: '口座番号は半角数字のみ使用できます',
    }
  },
  computed: {
    isFormCompleted: function() {
      if(this.referral_doc) {
        if (
          !this.referral_doc.account_number ||
          !this.referral_doc.account_name ||
          !this.referral_doc.account_type ||
          !this.referral_doc.bank_code ||
          !this.referral_doc.branch_code
        ) {
          return false
        }
        return true
      }

      // 初期入力時のバリデーション
      if (
        !this.draft_referral.account_number ||
        !this.draft_referral.account_name ||
        !this.draft_referral.account_type ||
        !this.draft_referral.bank_code ||
        !this.draft_referral.branch_code
      ) {
        return false
      }
      return true
    },
  },
  methods: {
    // 選択したdocをMenuから受け取る
    getPageId (value) {
      this.doc = value
    },
    async listenReferralData () {
      let doc = await Config.REFERRAL_COLLECTION.doc(this.$store.state.user.uid).get()
      this.referral_doc = doc.data() ? doc.data() : this.draft_referral
    },
    async submit_referral (p) {
      const isValid = await this.$validator.validate()
        if(!isValid) return

      // ボタンの非アクティブ
      this.displayButtons = false

      // page_id兼ドキュメントID発行
      let draft_referral = p ? p : this.draft_referral
      let listenReferralData = this
      let swal = this.$swal

      Config.REFERRAL_COLLECTION.doc(this.$store.state.user.uid)
      .set(draft_referral, { merge: true })
      .then(function () {
          swal.fire({
            title: '',
            text: '保存しました',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(function(val) {
            if (val) {
              listenReferralData.listenReferralData()
            }
        })
      }).catch(function (e) {
        swal('error', '保存エラー：' + e, 'error')
      })
        // 送信ボタンアクティブに戻す
        this.displayButtons = true
        return
      },
    onBlur: function() {
      this.autosave(this.referral_doc)
    },
    autosave(p){  // 新規作成以外は自動保存
      let listenReferralData = this
      Config.REFERRAL_COLLECTION
        .doc(this.$store.state.user.uid)
        .set(p, { merge: true })
        .then(function(){
          listenReferralData.listenReferralData()
        })
    }
  },
  watch: {
    referral_doc: function (val) {
      const words = ['ァ','ィ','ゥ','ェ','ォ','ヵ','ヶ','ャ','ュ','ョ','ッ']
      const replace_words = ['ア','イ','ウ','エ','オ','カ','ケ','ヤ','ユ','ヨ','ツ']
      let i = words.length
      let input = val['account_name']

      while (i--) {
        if (input.indexOf(words[i]) !== -1) {
          // フラグ変数 match を使わず、ここでマッチした時の処理もやっておく
          input = input.replace(words[i], replace_words[i])
        }
      }
      this.referral_doc['account_name'] = input
    }
  },

  created () {
    this.unsubscribe_referral = this.listenReferralData()
  },
  beforeDestroy () {
    if(typeof this.unsubscribe_referral == 'function') {
      this.unsubscribe_referral()
    }
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.memo{
  font-weight: normal;
}
</style>

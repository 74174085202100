import { render, staticRenderFns } from "./CardChange.vue?vue&type=template&id=04970791&scoped=true&"
import script from "./CardChange.vue?vue&type=script&lang=js&"
export * from "./CardChange.vue?vue&type=script&lang=js&"
import style0 from "./CardChange.vue?vue&type=style&index=0&id=04970791&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04970791",
  null
  
)

export default component.exports
<template>
  <section class="section container">

  <div class="columns">

    <div class="column is-3">
      <Menu @getdoc='getPageId' />
    </div>

    <div class="column">

      <!-- <div v-if="doc['page_id'] === undefined
        || doc['page_id'] === null
        || pages.length === undefined
        || pages.length === 0">

        <div class="">
          <h2 class="is-size-4 has-text-weight-bold pb-1">ランディングページ作成サービスとは</h2>
          <p>
            ランディングページ作成サービスは、集客アップに特化したウェブサービスです。集客したいサービスごとに、Google検索に最適化した専用ページを無料で作成できます。
          </p>
        </div>

        <div>
          <h2 class="is-size-4 has-text-weight-bold pt-3 pb-1">ページを新規作成する</h2>
          <p>
            下記のボタンをクリックすると、データを入力する画面が表示されます。
          </p>
          <button @click="newPage('new')" class="button is-success mt-1 mb-3">ページを新規作成する</button>
        </div>

        <hr>

      </div> -->


      <h1 class="is-size-4 has-text-weight-bold">よくある質問</h1>
      <p class="">ランディングページ、Googleビジネスプロフィール、ネット広告、お支払いについてのFAQ（よくある質問）をご確認いただけます。</p>

      <hr>

      <h2 class="is-size-4 has-text-weight-bold">ランディングページのFAQ</h2>
      <p>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/lp-plan-ja" target="_blanc">無料プランと有料プランの違い</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/lp-change-plan-ja" target="_blanc">プランを変更する方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/lp-create-ja" target="_blanc">ページ作成のポイントと作成方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/add-contents-ja" target="_blanc">テキスト・画像の追加、修正方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/edit-image-ja" target="_blanc">画像編集の方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/offer-ja" target="_blanc">オファーの掲載ポイント</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/line-offer-ja" target="_blanc">LINE友だち追加特典の掲載ポイント</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/case-ja" target="_blanc">事例の掲載ポイント</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/lp-feature-ja" target="_blanc">特長（選ばれる理由）の掲載ポイント</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/lp-flyer-page-ja" target="_blanc">宣伝用チラシの作成方法と活用方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/flyer-line-ja" target="_blanc">LINE友だち募集用チラシの作成方法と活用方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/flyer-print-ja" target="_blanc">チラシ印刷時の注意点</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/lp-change-login-ja" target="_blanc">ログインメールアドレス、パスワードの変更方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/lp-page-delete-ja" target="_blanc">ページを削除する方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/lp-account-delete-ja" target="_blanc">アカウントを削除する方法</a>
      </p>
      <p>
        ※<a href="https://r.ilb.net/lp-faq-ja" target="_blanc">ランディングページのFAQ一覧を見る</a><br>
      </p>

      <hr>

      <h2 class="is-size-4 has-text-weight-bold" id="gbp">GoogleビジネスプロフィールのFAQ</h2>
      <p>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/gbp-register-ja" target="_blanc">新規登録する方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/gbp-setting-ja" target="_blanc">Googleビジネスプロフィール設定マニュアル</a><br>
      </p>
      <p>
        ※<a href="https://r.ilb.net/gbp-faq-ja" target="_blanc">GoogleビジネスプロフィールのFAQ一覧を見る</a><br>
      </p>

      <hr>

<!--
      <h2 class="is-size-4 has-text-weight-bold" id="ad">ネット広告のFAQ</h2>
      <p>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/ad-method-ja" target="_blanc">効果的なネット広告の出し方</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/ad-area-ja" target="_blanc">広告の配信地域</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/ad-budget-ja" target="_blanc">広告の月額予算の設定と、１日の上限予算</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/ad-hidden-ja" target="_blanc">広告が表示されていない理由</a><br>
      </p>
      <p>
        ※<a href="https://r.ilb.net/ad-faq-ja" target="_blanc">ネット広告のFAQ一覧を見る</a><br>
      </p>

      <hr>
-->

      <h2 class="is-size-4 has-text-weight-bold" id="paypal">支払方法のFAQ</h2>
      <p>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/payment-change-credit-card-ja" target="_blanc">支払いで使用しているクレジットカードを変更する方法</a><br>
        <span class="icon-question-circle"></span>&nbsp;<a href="https://r.ilb.net/payment-cancel-ja" target="_blanc">決済の停止（サービスの解約）方法</a><br>
      </p>

    </div>
  </div>
  </section>
</template>

<script>
import 'bulma/css/bulma.css'
import Menu from '@/components/Menu.vue'

export default {
  mixins: [Menu],
  components: {
    Menu,
  },
  data () {
    return {
      doc: {},
    }
  },
  methods: {
    getPageId (value) {
      this.doc = value
    }
  }
}

</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
</style>

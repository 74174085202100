<template>
  <section id="report-board" class="report-board">
    <div id="report" class="head-line is-size-4 has-text-weight-bold">レポート</div>

    <div class="datepicker-title mt-4 has-text-centered has-text-weight-bold">
      <div>年月を選択してください</div>
    </div>
    <b-field>
      <b-datepicker
        v-model="targetDate"
        type="month"
        icon="calendar-today"
        :mobile-native=false
        :date-formatter="(d) => { return d.toLocaleDateString('ja-JP', {year: 'numeric', month: 'long'}) }"
        :minDate="new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())"
        :maxDate="new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())"
        :month-names='["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]'
        @input="getAllReportDatas()">
      </b-datepicker>
    </b-field>

    <div v-if="this.reportDatas['update']" class="last-update">更新日時：{{ this.reportDatas['update'] }}</div>
    <div class="has-text-centered">レポートへの最新の数値反映は、最大で72時間後になります。</div>

    <div v-if="this.isLoading">
      <b-loading :active.sync="this.isLoading"></b-loading>
    </div>
    <div v-else>
      <!--
      <div v-if="this.pageData['plan'] == 'standard'" class="advertising-request-url">
        <a v-bind:href="accountData['advertisingRequestUrl']" target="_blank"><b-icon icon="lead-pencil" size="is-small"></b-icon> 広告の申込、広告費・決済日の変更はココをクリック</a>
      </div>
      <div v-else class="advertising-request-message">
        <div>現在、ランディングページを無料プランでご利用中のため、広告のお申込ができません。</div>
        <div>※有料プランに変更いただくと、「広告の申込・変更依頼」のフォームのリンクが表示されます。</div>
      </div>

      <b-tabs v-if="reportDatas['ga'] && reportDatas['ga']['daily'].length && Object.keys(reportDatas['ga']['total']).length && Object.keys(reportDatas['ga']['channel']).length" class="report-board-tabs" type="is-toggle">
        <b-tab-item label="全体レポート">
          <AdsTotalCard :report-data="reportDatas['ga']"/>
        </b-tab-item>

        <b-tab-item label="広告レポート">
          <AdsCard :target-date="targetDate" :account-data="accountData" :report-datas="reportDatas" :total-report-data="reportDatas['ads']"/>
        </b-tab-item>
      </b-tabs>
      -->
      <div v-if="reportDatas['ga'] && reportDatas['ga']['daily'].length && Object.keys(reportDatas['ga']['total']).length && Object.keys(reportDatas['ga']['channel']).length">
        <AdsTotalCard :report-data="reportDatas['ga']"/>
      </div>
      <div v-else class="content has-text-grey has-text-centered no-data">
        <p><b-icon icon="alert-circle" size="is-large"></b-icon></p>
        <p>表示するデータがありません。</p>
      </div>
    </div>

    <hr />

    <HowToAttractCustomers :page-data="pageData" :charges-data="reportDatas['charges_data']" :item-names="itemNames" @modalclose="$emit('modalclose')"/>
  </section>
</template>

<script>

import AdsTotalCard from './modules/AdsTotalCard'
// import AdsCard from './modules/AdsCard'
import HowToAttractCustomers from './modules/HowToAttractCustomers'

import Util from './lib/util'

import firebase from '@/firebase/firebaseInit'
import GaReport from './lib/gaReport'

export default {
  props: {
    pageData: Object
  },
  components: {
    AdsTotalCard,
    // AdsCard,
    HowToAttractCustomers,
  },
  data() {
    return {
      isLoading: false,
      targetDate: new Date(),
      accountData: {},
      reportDatas: this.clearReportDatas,
      itemNames: {},
    }
  },
  watch: {
    pageData: {
      immediate: true,
      async handler() {
        if (this.pageData['page_id']) {
          // 全てのレポートデータを取得
          this.getAllReportDatas()

          // レポート、集客数を増やす方法のボックスを閉じる
          if (document.getElementById("ads_total_table_check")) {
            document.getElementById("ads_total_table_check").checked = false
          }
        }
      }
    }
  },
  methods: {
    async getAllReportDatas() {
      this.clearReportDatas()

      if (!this.pageData['page_id'] || !this.accountData) {
        return
      }

      if (!this.targetDate) {
        this.targetDate = new Date()
      }

      this.isLoading = true

      // Promise.allで全てデータを一度に取得（Promise.allを多重化しているのは、取得結果を各広告ごとに分けるため）
      const results = await Promise.all([
        Promise.all(GaReport.getReportData('report_data/' + this.pageData['page_id'], this.targetDate)),  // results[0]
        firebase.firestore().collection('report_data').doc(this.pageData['page_id']).get(),               // results[1]
        firebase.firestore().collection('charges_data').doc(this.pageData['page_id']).get(),              // results[2]
        firebase.firestore().collection('page_data').doc('param').get()                                   // results[3]
      ])
      
      this.reportDatas['ga'] = GaReport.createReportData(results[0][0], results[0][1], this.targetDate)
      this.reportDatas['update'] = results[1].exists ? Util.dateFormatter(results[1].data()['last_date'].toDate(), 'YYYY/MM/DD hh:mm') : ''
      this.reportDatas['charges_data'] = results[2].data()
      this.itemNames = results[3].data()

      this.isLoading = false
    },
    clearReportDatas() {
      this.reportDatas = {
        ga: {daily: [], total: {}, channel: {}},
        gs: {daily: [], total: {}, status: ''},
        gdn: {daily: [], total: {}, status: ''},
        ys: {daily: [], total: {}, status: ''},
        ydn: {daily: [], total: {}, status: ''},
        fb: {daily: [], total: {}, status: ''},
        ig: {daily: [], total: {}, status: ''},
        ar: {daily: [], total: {}, status: '', listCounts: 0},
        ads: {},
        charges_data: {},
        update: ''
      }
    }
  },
  updated: function() {
    if (this.$route.hash && this.$route.hash.match(/^#.+$/)) {
      const id = this.$route.hash.replace('#', '')
      const pos = Util.getElementPos(id)
      window.scrollTo(0, pos.y);
    }
  }
}
</script>

<style>
.datepicker input {
  width: auto;
}
.datepicker input[readonly="readonly"]{
  background-color: white;
  color: #363636;
}

.report-board {
  font-size: 13px;
}

.report-board .head-line{
  padding: 0px 5px;
  max-width: 720px;
  margin: 0px auto;
  font-size: 16px;
}

.report-board .last-update {
  width: 300px;
  margin:5px auto;
  text-align: center;
}

.report-board .advertising-request-url {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.report-board .advertising-request-message {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: red;
}

.report-board .no-data {
  margin-top: 30px;
}

.report-board table {
  min-width: 240px;
  margin: 0px auto;
  width: max-content;
}

.report-board .field {
  margin: 0px auto;
  width: max-content;
  text-align: center;
}

.report-board .tooltip {
  width: 100%;
}

.report-board-tabs nav {
  width: min-content;
  margin: 0px auto;
  padding: 0px;
}

.report-board-tabs ul {
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .report-board nav li {
    font-size: 12px;
  }
}
</style>

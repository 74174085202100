import Vue from 'vue/dist/vue.esm'
import Router from 'vue-router'
import Vuex from 'vuex'
import Main from '@/components/Main'
import Login from '@/components/Login'
import MailChange from '@/components/MailAddressChange'
import PasswordReset from '@/components/PasswordReset'
import PasswordChange from '@/components/PasswordChange'
import Faq from '@/components/Faq'
// import SignUp from '@/components/SignUp'
// import SignUpAgent from '@/components/SignUpAgent'
import Flyer from '@/components/Flyer'
import AdSetting from '@/components/AdSetting'
import Report from '@/components/Report'
import Referral from '@/components/Referral'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueSweetalert2 from 'vue-sweetalert2'
import VeeValidate, { Validator } from 'vee-validate'
import 'sweetalert2/dist/sweetalert2.min.css'
import ja from 'vee-validate/dist/locale/ja'
import Config from './config.js'
import mixInFunction from './mixInFunction.js';
Vue.mixin(mixInFunction);

Vue.config.productionTip = false
Vue.use(Router)
Vue.use(Vuex)
Vue.use(Buefy)
Vue.use(VueSweetalert2)
Vue.use(VeeValidate)
Validator.localize('ja', ja)

const App = {
  template: `
    <div>
      <Main
      ref="main" />
    </div>
    `,
  components: { Main }
}

let app
Config.FIREBASE_AUTH.onAuthStateChanged(
  p => {
    if (app) {
      // vueオブジェクトが存在した場合、storeのuserを呼び出し、pを入れている
      app.$store.commit('user', p)
    } else {
      const router = new Router(
        {
          mode: 'history',
          routes:
            [
              { path: '/login', component: Login },
              // { path: '/signup', component: SignUp },
              // { path: '/signup-agent', component: SignUpAgent },
              { path: '/mailchange', component: MailChange },
              { path: '/passwordreset', component: PasswordReset },
              { path: '/passwordchange', component: PasswordChange },
              { path: '/flyer', component: Flyer },
              { path: '/ad', component: AdSetting },
              { path: '/faq', component: Faq },
              { path: '/page_report', component: Report },
              { path: '/referral', component: Referral},
              // { path: '/:id/page_apply', component: App, name: 'checkout'},
              // { path: '/:id/plan_upgrade', component: App, name: 'checkout'},
              // { path: '/:id/plan_apply', component: App, name: 'checkout'},
              // { path: '/:id/plan_downgrade', component: App, name: 'plandowngrade'},
              // { path: '/:id/card_change', component: App, name: 'cardchange'},
              // { path: '/:id/plan_cancel', component: App, name: 'plandowngrade'},
              { path: '/:id/flyer', component: Flyer },
              { path: '/:id/page_report', component: Report },
              { path: '/:id', component: App },
              { path: '/main', component: App }, // 初回遷移用
              { path: '/', component: App },
              { path: '/*', component: { template: '<div>NotFound</div>' } }
            ],
          scrollBehavior: (to, from, savedPosition) => {
            if (savedPosition) {
                return savedPosition;
            }

            if (to.hash) {
                return {selector: to.hash}
            }
            return {x: 0, y: 0};
          }
        }
      )
      router.beforeEach(
        async (to, from, next) => {
          if (Config.FIREBASE_AUTH.currentUser) {
            if (to.path === '/login') await Config.FIREBASE_AUTH.signOut()
              next()
          } else {
            if (to.path !== '/signup' && to.path !== '/login' && to.path !== '/passwordreset' && to.path !== '/signup-agent') {
              next({ path: 'login', query: { redirect: to.path } })
            } else {
              next()
            }
          }
        }
      )
      app = new Vue(
        {
          el: '#app',
          store: new Vuex.Store(
            {
              state: {
                user: p,
                page_id: '',
                referral_data:''
              },
              mutations: {
                user: function (state, payload) {
                  state.user = payload
                },
                page_id: function (state, payload) {
                  state.page_id = payload
                },
                referral_data: function (state, payload) {
                  state.referral_data = payload
                },
              }
            }
          ),
          router,
          template: `<router-view />`
        }
      )
    }
  }
)

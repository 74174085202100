<template>
  <div>
    <b-notification aria-close-label="Close notification">
      お客様からよく聞かれる質問と回答を５件まで掲載できます。<br>
      ※サービスの内容、サービス利用の流れ、予約・申込の方法や支払方法、年齢・性別・託児の有無など<br>
      <br>
      ※タイトルの右側にある「︙」をクリックすると、順番の変更が可能です。
    </b-notification>

    <img class="pb-3" src="https://storage.googleapis.com/admin-ilb.appspot.com/ilbio-jp/faq.png">
    <!--ここから-->
    <div v-if="updateFlg">


      <div class="content" v-for="key of 5" :key="key">
        <div class="move-panel">
          <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">
          <template #trigger>
            <b-icon icon="dots-vertical"></b-icon>
          </template>
            <b-dropdown-item aria-role="listitem" @click="menuMove('qa', (key -1),'up', true)" :disabled="(key-1) === 0">一番上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('qa',(key-1),'up')" :disabled="(key-1) === 0">一つ上に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('qa',(key-1),'down')" :disabled="(key-1) === (pageData['qa'].length - 1)">一つ下に移動</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="menuMove('qa',(key-1),'down', true)" :disabled="(key-1) === (pageData['qa'].length - 1)">一番下に移動</b-dropdown-item>
          </b-dropdown>
        </div>

        <b-collapse
          aria-id="contentIdForA13y3"
          class=""
          animation="slide"
          v-model="isOpen">
          <template #trigger>
            <div
              class="panel-heading mb-3"
              role="button"
              aria-controls="contentIdForA13y3">
              <strong v-if="pageData['qa'][key-1]['question']">{{ pageData['qa'][key-1]['question']}}</strong>
              <strong v-else>よくある質問{{key}}の質問を入力してください</strong>
            </div>
          </template>
          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'qa_question_' + key">よくある質問{{key}}の質問</label>
            <p class="pb-1" v-if="(key-1) === 0">
              例）予約は必要ですか？
            </p>
            <b-input :id="'qa_question_' + key" v-model="pageData['qa'][key-1]['question']" maxlength="30"></b-input>
          </div>

          <div class="field pb-3">
            <label class="label has-text-weight-bold" :for="'qa_answer_' + key">よくある質問{{key}}の回答</label>
            <p class="pb-1" v-if="(key-1) === 0">
              例）はい。当店は完全予約制となっていますので、お電話やLINE、予約フォームからご予約をお願いします。
            </p>
            <b-input :id="'qa_answer_' + key" v-model="pageData['qa'][key-1]['answer']" maxlength="150" type="textarea"></b-input>
          </div>

          <div class="field">
            <label class="label has-text-weight-bold" :for="'qa_movie_' + key">よくある質問{{key}}の動画</label>
            <p class="pb-1">
              質問と回答の動画（YouTubeの動画）のID（赤字の部分）を入力してください。<br>
              ※下記のように IDの後ろに&がついている場合は、&の前までがIDとなります。<br>
              https://www.youtube.com/watch?v=<span class="has-text-danger">z3foLsvz_kg</span>&t=35
            </p>
            <p class="pb-1">
              <a href="https://r.ilb.net/movie-url-ja" target="_blank">YouTube動画のIDを確認する方法を見る&nbsp;<span class="icon-external-link"></span></a>
            </p>
            <b-input :id="'qa_movie_' + key" v-model="pageData['qa'][key-1]['movie']" maxlength="11"></b-input>
          </div>
        </b-collapse>
      </div>
    </div>
    <!-- ここまで -->
  </div>
</template>

<script>
import 'bulma/css/bulma.css'

export default {
  props: ['doc','title'],
  computed: {
    pageData: {
      get() {
        return this.doc;
      },
      set(data) {
        this.$emit('doc', data);
      }
    }
  },
  methods: {
  },
  data () {
    return {
      isOpen: true,
      updateFlg: true // 再描画用
    }
  }
}
</script>

<style>
input[readonly='readonly']{
background-color:#c0c0c0;
color:#666;
}
.move-panel {
  position:relative;
  top:12px;
  left: -1%;
}
</style>
